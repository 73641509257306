import React, { useState, useEffect } from "react";
import api from "../services/api";
import { Redirect, Route, Link } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { bool } from "prop-types";

export default function SimpleSlider(props) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 5,
    slidesToScroll: 1,
    loop: true,
    autoplay: false,
    centerMode: true,
    focusOnSelect: true,
    centerPadding: 5,
    arrows: true,
    slickNext:
      '<button type="button" class="slick-prev"><i className="fas fa-chevron-left"></i></button>',
    slickNext:
      '<button type="button" class="slick-next"><i className="fas fa-chevron-left"></i></button>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 670,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [gameListsData, setGameListsData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(9);
  const getGames = async () => {
    if (page == 1) {
      setGameListsData([]);
    }
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      page: page,
      game_types: ["popular"],
      specific_games: props.specificGames,
    });

    if (response.status === 200 && response.data) {
      // alert(gameListsData.length)
      const tempGameList = gameListsData;
      var mergedGameList = tempGameList.concat(
        response.data.games.popular.gamelist
      );
      setGameListsData(mergedGameList);
    }
  };

  useEffect(async () => {
    getGames();
  }, []);
  return (
    <Slider {...settings}>
      {gameListsData.length > 0 &&
        gameListsData.map((game, index) => (
          <div className="single_game">
            <Link to={"/play/" + game.slug + "/real"}>
              <img src={game.game_snapshot_url} />
            </Link>
            <div className="identyText-overview"></div>
          </div>
        ))}

     
    </Slider>
  );
}
