import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "../../services/auth";
import ls from "local-storage";
import AppContext from "../../contexts/AppContext";
import api from "../../services/api";

import { Button } from "react-bootstrap";
import SocialLogin from "./SocialLogin";

const SocialRegister = (props) => {
  // const menuOpen = props.menuOpen;


  const [errors, setErrors] = useState({});

  const [registerData, setRegisterData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    rePassword: "",
    agreeToTerms: 0,
    acceptCountryLimitation: 0,
  });

  const updateRegisterForm = (field, value) => {
    setRegisterData({ ...registerData, [field]: value });
    console.log('setRegisterDatasetRegisterData', registerData);
  };

  const registerInputChange = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    if (field == "agreeToTerms") {
      var checked = ev.target.checked;
      if (checked) {
        value = 1;
      } else {
        value = 0;
      }
    }
    if (field == "acceptCountryLimitation") {
      var checked = ev.target.checked;
      if (checked) {
        value = 1;
      } else {
        value = 0;
      }
    }

    updateRegisterForm(field, value);

  };

  const registerValidateForm = () => {
    var valid = true;
    var errorsTmp = {};

    if (!registerData.agreeToTerms) {
      errorsTmp = {
        ...errorsTmp,
        agreeToTerms: "Please read & accept terms & privacy policy",
      };
      valid = false;
    }
    if (!registerData.acceptCountryLimitation) {
      errorsTmp = {
        ...errorsTmp,
        acceptCountryLimitation: "Please read & accept country policy",
      };
      valid = false;
    }
    setErrors(errorsTmp);
    return valid;
  };



  const [loginData, setLoginData] = useState({});
  const [applyLogin, setApplyLogin] = useState(false);

  const registrationAction = async (ev) => {
    ev.preventDefault();
    var valid = registerValidateForm();
    if (valid) {    
      var response = await api.post("/social-signup", registerData);      
      console.log(response.data);
      if (response.data.status == "success") {      
        // history.push("/login");
        setApplyLogin(true);
        setLoginData({
          email: registerData.email,
          socialId: registerData.socialId,
          provider: registerData.provider,
        });
      } else {
        setErrors(response.data.errors);
      }
    }
  };



  useEffect(() => {
    setRegisterData(props.socialRegisterData);
  }, [props]);




  return (
    <>
      {applyLogin && (
        <SocialLogin loginData={loginData} />
      )}
      
      <div className="register">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-10 col-sm-10 col-md-8 col-lg-5">
              <div className="form_area">
                <div className="common_headline text-center">
                  Please agree to the following terms
                </div>
                <div className="input_item">
                  <div class="form-check">
                    <label
                      for="register_tos-accept-checkbox"
                      class="form-check-label"
                    >
                      <input
                        id="register_tos-accept-checkbox"
                        name="agreeToTerms"
                        class="form-check-input"
                        type="checkbox"
                        onChange={registerInputChange}
                        value={registerData.agreeToTerms}
                      />
                      I confirm the following: I am at least 18 years old; I
                      agree to the{" "}
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to="/terms"
                      >
                        Terms of Use
                      </Link>{" "}
                      and{" "}
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to="/privacy"
                      >
                        Privacy Policy
                      </Link>{" "}
                      (including, for California residents, the{" "}
                      <Link target="_blank" rel="noopener noreferrer" to="#">
                        California Privacy Notice
                      </Link>
                      ); I do not have any existing Wild World Casinoaccounts. I
                      understand Wild World Casinohas the right to cancel all
                      duplicate accounts and withold winnings made with such
                      accounts.
                    </label>
                  </div>
                  <div className="error_msg">{errors.agreeToTerms}</div>
                </div>
                <div className="input_item">
                  <div class="form-check">
                    <label
                      for="register_region-accept-checkbox"
                      class="form-check-label"
                    >
                      <input
                        onChange={registerInputChange}
                        id="register_region-accept-checkbox"
                        name="acceptCountryLimitation"
                        class="form-check-input "
                        type="checkbox"
                        value={registerData.acceptCountryLimitation}
                      />
                      I understand that if I live outside United States or
                      Canada, or within Washington State, Idaho or Quebec, I
                      cannot redeem my Sweeps Coins due to local laws.
                    </label>
                  </div>
                  <div className="error_msg">
                    {errors.acceptCountryLimitation}
                  </div>
                </div>
                <div className="login_button">
                  <Button onClick={registrationAction} className="login_btn">Confirm</Button>
                </div>
                <br/>
                <div className="login_button d-none">
                  <Button className="login_btn">Back</Button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialRegister;
