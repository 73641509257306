import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import CategoryGames from "elements/CategoryGames";
import SearchBox from "elements/SearchBox";
export default function Home(props) {
  const [bonusModal, setBonusModal] = useState(false);
  const openBonusModal = () => {
    setBonusModal(false);
  };

  useEffect(() => {
    openBonusModal();
  }, []);

  const [categoryName, setCategoryName] = useState("Slots");

  return (
    <>
      <div className="home ">
        <div className="box_container">
          <div className="home_banner ">
            <div className="hero_details">
              <div className="delay fadeInUp">
                <img
                  className="hero__logo"
                  alt="hero banner logo"
                  src={require("../assets/images/banners/buffelow.png").default}
                />

                <p className="hero_description">
                  The blitz is on again. Now with more ways to win!
                </p>
              </div>
              <div className="button_section">
                <Link
                  className="common_button btnType-two is-imgbg"
                  to="/games/slots"
                >
                  <span>Play Now</span>
                  <svg width="13px" height="10px" viewBox="0 0 13 10">
                    <path d="M1,5 L11,5"></path>
                    <polyline points="8 1 12 5 8 9"></polyline>
                  </svg>
                </Link>
              </div>
            </div>

            <img
              className="hero__new-game fadeInUp delay"
              alt="hero banner new game"
              src={
                require("../assets/images/banners/hero_banner_new_game.png")
                  .default
              }
            />
          </div>
        </div>
        <div className="box_container">
          <div className="content-featuresBox ">
            {/* <CategoryGames
              categoryName="Popular Slots"
              perPage="5"
              title="Popular Games"
            />
            <CategoryGames
              categoryName="Popular Slots"
              perPage="5"
              title="Recently Played"
            /> */}
            <div className="row">
              {/* <div className="col-lg-6 col-12">
                <div className="common_heading">{categoryName}</div>
              </div> */}
              <div className="col-lg-10 col-12">
                <div className="filter_game">
                  <ul>
                    <li>
                      <Link
                        className={
                          "common_button in_game_filter " +
                          (categoryName == "Slots" ? "active" : "")
                        }
                        onClick={() => {
                          setCategoryName("Slots");
                        }}
                      >
                        SLOTS
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={
                          "common_button in_game_filter " +
                          (categoryName == "Table Games" ? "active" : "")
                        }
                        onClick={() => {
                          setCategoryName("Table Games");
                        }}
                      >
                        TABLE GAMES
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2">
                <SearchBox {...props} />
              </div>
              <div className="clearfix"></div>
            </div>

            <CategoryGames categoryName={categoryName} perPage="40" title="" />
          </div>
        </div>
      </div>
      <Modal show={bonusModal}>
        <Modal.Body className="buy_modal">
          <div className="buy_coins">
            <div className="buy_coins_content">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-10 col-sm-10 col-md-8 col-lg-6 col-xl-4">
                    <div className="daily_bonus_modal">
                      <div className="page_title golden">Daily Bonus</div>
                      <div className="common_paragraph text-center">
                        Log in daily to claim your bonus
                      </div>
                      <div className="offer_img">
                        <img
                          src={
                            require("../assets/images/icons/bonus.svg").default
                          }
                        />
                      </div>
                      <div className="common_headline text-center">
                        200,000 Gold Coins
                        <br />
                        +
                        <br />1 Sweeps Coin
                      </div>
                      <div className="button_section">
                        <Link to="#" className="yellow_btn">
                          Claim
                        </Link>
                      </div>

                      <div className="close_modal text-center">
                        <Link
                          onClick={() => {
                            setBonusModal(false);
                          }}
                          to="#"
                        >
                          Close{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
