import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Accordion, Tab, Row, Col, Nav, Button } from "react-bootstrap";
import config from "configs/config.js";
import api from "services/api";
export default function Contact(props) {
  //FAQ functionality START'S here
  const [faqData, setFaqData] = useState([]);
  const getFaq = async () => {
    var response = await api.get("/get-faqs");

    if (response.status === 200 && response.data) {
      setFaqData(response.data);
      console.log("response.dataresponse.data", response.data);
    }
  };

  useEffect(async () => {
    getFaq();
  }, []);
  //FAQ functionality END'S here

  return (
    <>
      <section className="box_container">
        <div className="privacy supportpage">
          <Tab.Container id="left-tabs-example" defaultActiveKey="FAQ">
            <Row>
              <Col sm={12}>
                <Row>
                  <Col md={9} sm={12} className="offset-md-3 offset-0">
                    <Nav
                      variant="pills"
                      className="d-flex align-items-center tabs-mainContent"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="FAQ">FAQ</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link to="/contact-details" eventKey="Contact">
                          Contact
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                </Row>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="FAQ">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="account"
                    >
                      <Row>
                        <Col sm={3}>
                          <Nav variant="pills" className="tabs2-mainContent">
                            {faqData.length > 0 &&
                              faqData.map((value, index) => (
                                <Nav.Item>
                                  <Nav.Link eventKey={value.category_group}>
                                    {value.category}
                                  </Nav.Link>
                                </Nav.Item>
                              ))}
                          </Nav>
                        </Col>
                        <Col sm={9}>
                          <Tab.Content className="tabs2-mainContentItems">
                            {faqData.length > 0 &&
                              faqData.map((value, index) => (
                                <Tab.Pane eventKey={value.category_group}>
                                  <Accordion defaultActiveKey="0">
                                    {value.faqs.length > 0 &&
                                      value.faqs.map((value2, index2) => (
                                        <Accordion.Item eventKey={index2}>
                                          <Accordion.Header>
                                            {value2.title}
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            {value2.description}
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      ))}
                                  </Accordion>
                                </Tab.Pane>
                              ))}
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Tab.Pane>

                  {/* for contact */}
                  <Tab.Pane className="contactBox" eventKey="Contact">
                    <Row className="justify-content-center">
                      <Col xl={8} lg={9} sm={12}>
                        <div className="contactForm">
                          <div className="form_area">
                            <div className="contactheading">
                              Ask Us Anything.
                            </div>
                            <Row>
                              <Col sm={6}>
                                <div className="input_item">
                                  <input
                                    type="text"
                                    placeholder="Your Name"
                                    name="name"
                                  />
                                </div>
                              </Col>
                              <Col sm={6}>
                                <div className="input_item">
                                  <input
                                    type="email"
                                    placeholder="Your Email Address"
                                    name="email"
                                  />
                                </div>
                              </Col>
                              <Col sm={12}>
                                <div className="input_item">
                                  <input
                                    type="text"
                                    placeholder="Subject"
                                    name="subject"
                                  />
                                </div>
                              </Col>
                              <Col sm={12}>
                                <div className="input_item">
                                  <textarea
                                    type="text"
                                    name="message"
                                    placeholder="Your Message"
                                  />
                                </div>
                              </Col>
                              <Col md={3} sm={12}>
                                <div className="submite_button">
                                  <Button className="submit_btn">Submit</Button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="socials-widgets">
                            <Row className="align-items-end">
                              <Col md={6} sm={12}>
                                {/* <div className="ContactD-heading">
                                  Get In Touch
                                </div>
                                <div className="ContactD-address">
                                  {" "}
                                  169 Mohawk Path, Holliston, MA 01746{" "}
                                </div>
                                <div className="ContactD-supportlink">
                                  support@casino.app
                                </div>
                                <div className="ContactD-nmbr">
                                  +00-000-000-0000
                                </div> */}
                              </Col>
                              <Col md={6} sm={12}>
                                <ul class="social">
                                  <li>
                                    <Link className="link facebook">
                                      <span class="fab fa-facebook-f icon"></span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="link twitter">
                                      <span class="fab fa-twitter icon"></span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="link linkdin">
                                      <span class="fab fa-linkedin-in icon"></span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link className="link google-plus">
                                      <span class="fab fa-google-plus-g icon"></span>
                                    </Link>
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </section>
    </>
  );
}
