import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "../services/auth";
import ls from "local-storage";
import AppContext from "../contexts/AppContext";
import api from "../services/api";
import emailValidate from "../services/emailValidate";

const Footer = (props) => {
  return (
    <>
      <footer className="box_container">
        <section class="footer">
          <div class="container">
            <div className="footerLink--box">
              <div className="row">
                <div class="brands our-Brands col-12 col-lg-6 col-md-12">
                  <div className="Footer_logo">
                    <Link to="/">
                      <img src={require("../assets/images/logos/moolah-bucks-logo-white.svg").default} />
                    </Link>
                  </div>
                  <p>
                    You understand that you are providing information to First Floor Ltd. The information you provide
                    will only be used to administer this promotion. NO PURCHASE NECESSARY to enter Sweepstakes.
                    SWEEPSTAKES ARE VOID WHERE PROHIBITED BY LAW.
                  </p>
                </div>
                <div class="quicklinks col-12 col-lg-3 col-md-6">
                  <h4 className="section_title">Quick Links</h4>
                  <ul>
                    <li>
                      <Link to="/terms-and-conditions">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy" target="blank">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/responsible-social-gameplay-policy" target="blank">
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Responsible Social Gameplay
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          props.setSupportSidebarOpen(true);
                        }}
                      >
                        <span className="linkIcon">
                          <i class="fas fa-arrow-right"></i>
                        </span>{" "}
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>

                <div class="brands socials-Link col-12 col-lg-3 col-md-6">
                  <h4 className="section_title">Socials Links</h4>
                  <ul className="social_link">
                    <li>
                      <Link to="#">
                        <i class="fab fa-facebook"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i class="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i class="fab fa-google-plus-g"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i class="fab fa-linkedin-in"></i>
                      </Link>
                    </li>
                  </ul>
                  <ul class="social d-none">
                    <li>
                      <Link className="link facebook">
                        <span class="fab fa-facebook-f icon"></span>
                      </Link>
                    </li>
                    <li>
                      <Link className="link twitter">
                        <span class="fab fa-twitter icon"></span>
                      </Link>
                    </li>
                    <li>
                      <Link className="link linkdin">
                        <span class="fab fa-linkedin-in icon"></span>
                      </Link>
                    </li>
                    <li>
                      <Link className="link google-plus">
                        <span class="fab fa-google-plus-g icon"></span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <div class="clearfix"></div> */}
            <div class="footer_description">
              Copyright © 2022 Moolah Bucks. All rights reserved.
              <br />
              Moolah Bucks is owned and operated by First Floor Ltd. All payments are processed by First Floor Limited.
              <br />
              The registered address of First Floor Ltd. is Kalymnou 1, 4th floor, Agios Nikolaos, Kamares, 6037,
              Larnaca, Cyprus.
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
