import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "../services/auth";
import ls from "local-storage";
import AppContext from "../contexts/AppContext";
import api from "../services/api";
import FavoriteGame from "elements/FavoriteGame";

const CategoryGames = (props) => {
  // const menuOpen = props.menuOpen;

  console.log("isAuthenticatedisAuthenticated", props.isAuthenticated);
  const [seconds, setSeconds] = useState(4083958);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 60);
    return () => clearInterval(interval);
  }, []);

  // const [categoryName, setCategoryName] = useState(props.categoryName);
  // const [showSidebar, setShowSidebar] = useState(false);

  const openSidebar = () => {
    setShowSidebar(true);
  };
  const closeSidebar = () => {
    setShowSidebar(false);
  };

  // alert(props.categoryName);
  const [categoryName, setCategoryName] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [gameListsData, setGameListsData] = useState([]);
  const [popularSlots, setPopularSlots] = useState([]);
  const [tableGames, setTableGames] = useState([]);
  const [gameType, setGameType] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(props.perPage);
  const [title, setTitle] = useState(props.title);

  const getGames = async () => {
    if (page == 1) {
      setGameListsData([]);
    }
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      page: page,
      game_types: [gameType],
      specific_games: props.specificGames,
    });

    if (response.status === 200 && response.data) {
      // alert(gameListsData.length)
      if (gameType == "popular") {
        // const tempGameList = popularSlots;
        // var mergedGameList = tempGameList.concat(
        //   response.data.games.popular.gamelist
        // );
        // setPopularSlots(mergedGameList);
        console.log("responseresponseresponse", response.data.games.popular.gamelist);
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(response.data.games.popular.gamelist);
        setGameListsData(mergedGameList);
      } else if (gameType == "table_games") {
        // const tempGameList = tableGames;
        // var mergedGameList = tempGameList.concat(
        //   response.data.games.table_games.gamelist
        // );
        // setTableGames(mergedGameList);

        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(response.data.games.table_games.gamelist);
        setGameListsData(mergedGameList);
      } else if (gameType == "video_slots") {
        // const tempGameList = videoSlots;
        // const tempGameList = [];
        const tempGameList = gameListsData;
        var mergedGameList = tempGameList.concat(response.data.games.video_slots.gamelist);

        // console.log("mergedGameList", mergedGameList);
        setGameListsData(mergedGameList);
        // setGameListsData(response.data.games.video_slots.gamelist);
      }
    }
  };

  useEffect(async () => {
    if (categoryName == "Popular Slots") {
      setGameType("popular");
    } else if (categoryName == "Table Games") {
      setGameType("table_games");
    } else if (categoryName == "Slots") {
      setGameType("video_slots");
    } else if (categoryName == "Jackpots") {
      setGameType("jackpots");
    } else if (categoryName == "All Games") {
      setGameType("popular");
    }

    setGameListsData([]);
    setPage(1);
    // alert(props.categoryName)
  }, [categoryName]);

  useEffect(async () => {
    setCategoryName(props.categoryName);
  }, [props.categoryName]);

  useEffect(async () => {
    setTitle(props.title);
  }, [props.title]);

  useEffect(async () => {
    if (gameType) {
      getGames();
    }
  }, [gameType, page]);

  const [showSidebar, setShowSidebar] = useState(false);

  const loadMoreGamesAction = (prm) => {
    setPage(page + 1);
    // setPerPage(40);
  };
  const hideNoImageFoundGames = () => {
    // setVisible(false);
  };

  return (
    <>
      <section className="game_content_area ">
        <div className="common_headline">{title}</div>

        {categoryName !== "Jackpots" && (
          <div className="games_list">
            {gameListsData.length > 0 &&
              gameListsData.map((game, index) => (
                <React.Fragment key={index}>
                  <div className="single_game">
                    <div className="game_thumb">
                      <img onError={hideNoImageFoundGames} src={game.game_snapshot_url} />
                      <div className="identyText-overview">
                        {/* <h6 className="game_name">{game.game_name}</h6> */}

                        <div class="game_action">
                          {!isAuthenticated && (
                            <Link class="game_play_icon" to={"/play/" + game.slug + "/demo"}>
                              <i class="fas fa-play"></i>
                            </Link>
                          )}
                          {isAuthenticated && (
                            <Link class="game_play_icon" to={"/play/" + game.slug + "/real"}>
                              <i class="fas fa-play"></i>
                            </Link>
                          )}
                        </div>
                        <h3 class="game_provider_name">{game?.provider?.name}</h3>
                        {/* <Link
                          className=" common_button btnType-three"
                          to={"/play/" + game.slug + "/real"}
                        >
                          <span>Play now</span>
                        </Link> */}

                        {/* <Link className="common_button btnType-three" to="#">
                          <span>Play now</span>
                        </Link> */}
                      </div>
                      <FavoriteGame gameid={game.id} is_favorite_game={game.is_favorite_game} {...props} />
                    </div>
                    <h4 className="game_title">{game.game_name}</h4>
                    {/* <div className="mask_layer"></div> */}

                    {/* <div className="identyText-overview">
                      <p>{game.game_name}</p>
                    </div> */}
                  </div>
                </React.Fragment>
              ))}
          </div>
        )}

        {categoryName === "Jackpots" && (
          <div className="games_list">
            <div className="single_game">
              <Link to="#">
                <img src={require("../assets/images/games/Rounders.png").default} />
                <div className="mask_layer"></div>
              </Link>
              <div className="sc_badge no_bg golden">
                <strong>Grand GC</strong>
                {seconds}
              </div>
            </div>
            <div className="single_game group_game">
              <div className="single_game">
                <Link to="#">
                  <img src={require("../assets/images/games/Ra To Riches.png").default} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="single_game">
                <Link to="#">
                  <img src={require("../assets/images/games/Sugar Kisses.png").default} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="sc_badge">
                <strong>Grand GC</strong> {seconds}
              </div>
            </div>
            <div className="single_game group_game">
              <div className="single_game">
                <Link to="#">
                  <img src={require("../assets/images/games/Wicked Reels.png").default} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="single_game">
                <Link to="#">
                  <img src={require("../assets/images/games/Wizardry.png").default} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="sc_badge">
                <strong>Grand GC</strong> {seconds}
              </div>
            </div>

            <div className="single_game">
              <Link to="#">
                <img src={require("../assets/images/games/Aztec Sun Stone.png").default} />
                <div className="mask_layer"></div>
              </Link>
              <div className="sc_badge no_bg golden">
                <strong>Grand GC</strong> {seconds}
              </div>
            </div>
            <div className="single_game group_game">
              <div className="single_game">
                <Link to="#">
                  <img src={require("../assets/images/games/Big Fat Turkey.png").default} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="single_game">
                <Link to="#">
                  <img src={require("../assets/images/games/Fruitti Friends.png").default} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="sc_badge">
                <strong>Grand GC</strong> {seconds}
              </div>
            </div>
            <div className="single_game group_game">
              <div className="single_game">
                <Link to="#">
                  <img src={require("../assets/images/games/Halloween Toons.png").default} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="single_game">
                <Link to="#">
                  <img src={require("../assets/images/games/Puppy Party.png").default} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="sc_badge">
                <strong>Grand GC</strong> {seconds}
              </div>
            </div>
            <div className="single_game group_game">
              <div className="single_game">
                <Link to="#">
                  <img src={require("../assets/images/games/Rounders.png").default} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="single_game">
                <Link to="#">
                  <img src={require("../assets/images/games/Ra To Riches.png").default} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="sc_badge">
                <strong>Grand GC</strong> {seconds}
              </div>
            </div>
          </div>
        )}

        <Link onClick={loadMoreGamesAction} className="loadMoreBtn">
          Load More
        </Link>
      </section>
    </>
  );
};

export default CategoryGames;
