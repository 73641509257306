// var configEnv = "development";
// var configEnv = "staging";
var configEnv = "production";

var config = {
  //##########Live config##########
  production: {
    appUrl: "https://moolahbucks.com",
    apiUrl: "https://api.moolahbucks.com/api/v1",
    staticUrl: "https://api.moolahbucks.com/api/v1",
    liveChatUrl: "https://headquarters98.moolahbucks.com/public/livechat/php/app.php?widget-inline",
    debug: false,
  },

  //#########Stage config##########
  staging: {
    appUrl: "https://mb2.chimptestserver.com",
    apiUrl: "https://mb2api.chimptestserver.com/api/v1",
    staticUrl: "https://mb2api.chimptestserver.com/api/v1",
    liveChatUrl: "https://mb2bossoffice.chimptestserver.com/public/livechat/php/app.php?widget-inline",
    debug: false,
  },

  //##########Local config##########
  development: {
    apiUrl: "http://localhost/casinoapi/api/v1",
    staticUrl: "http://localhost/casinoapi/api/v1",
    liveChatUrl: "http://localhost/casinoadmin/public/livechat/php/app.php?widget-inline",
    debug: true,
  },
};

export default config[configEnv];
