import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "services/api";
import { Badge, ProgressBar } from "react-bootstrap";

import InputBox from "elements/InputBox";
import { __ } from "services/Translator";

export default function ProfileDetails(props) {
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;
  const accountProgress = props.accountProgress;
  const getAccountProgress = props.getAccountProgress;
  // const setSpinner = props.setSpinner;

  // const __ = (text) => text;

  const genders = [
    { label: __("Gender"), value: null },
    { label: __("Male"), value: "Male" },
    { label: __("Female"), value: "Female" },
    { label: __("Other"), value: "Other" },
  ];

  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const currencies = [
    { label: __("Currency"), value: null },
    { label: "USD", value: "USD" },
  ];
  const [phoneCodes, setPhoneCodes] = useState([]);

  const getCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200) {
      setCountries(
        response.data.map((val) => {
          return { label: val.nicename, value: val.code };
        })
      );
      setPhoneCodes(
        response.data.map((val) => {
          return {
            label: "+" + val.phonecode + " (" + val.code + ")",
            value: "+" + val.phonecode,
          };
        })
      );
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      setLanguages(
        response.data.map((val) => {
          return { label: val.language, value: val.code };
        })
      );
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const getProfile = async () => {
    props.setSpinner(true);
    var response = await api.get("/profile");
    props.setSpinner(false);
    if (response.status === 200) {
      setForm(response.data);
      setIsDataFetched(true);
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const [editMode, setEditMode] = useState(true);

  const submitProfile = async (ev) => {
    ev.preventDefault();
    props.setSpinner(true);
    var response = await api.post("/profile/save", {
      oldPassword: form.oldPassword,
      password: form.password,
      repassword: form.repassword,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      props.showAlert("Success!!", response.data.message);
      setForm({
        ...form,
        oldPassword: "",
        password: "",
        repassword: "",
      });
      setIsDataFetched(true);
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    dateOfBirth: "",
    gender: "",
    currency: "",
    language: "",
    phoneCode: "",
    phone: "",
  });

  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getAllData = async (force) => {
    if (!isDataFetched || force) {
      props.setSpinner(true);
      await getCountries();
      await getLanguages();
      await getProfile();
      // setSpinner(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <>
      <h3 className="tabName white">{__("Account Details")}</h3>
      <div className="row">
        <div className="col-md-3 d-none">
          <div className="user_index_area">
            <div className="user_avatar">
              <img
                src={require("assets/images/header/user-avatar.jpg").default}
              />
              {/*<div className="icon">
                <i className="fas fa-mars"></i>
              </div>*/}
            </div>

            <div className="user_name">
              {form.firstName + " " + form.lastName}
            </div>
            <div className="user_id first">{__("ID Casino Number")}:</div>
            <div className="user_id last"> {form.pin}</div>
            <Link
              onClick={() => setEditMode(true)}
              className="common_btn gray medium"
              to="#"
            >
              {__("Edit Account")}
            </Link>
          </div>
        </div>

        <div className="col-md-12">
          <div className="user_blocks">
            <div className="single_block  ">
              <div className="item">
                <div className="user_item">
                  <div className="name">
                    {form.firstName + " " + form.lastName}
                    <span className="level">Lv {form.identity}</span>
                  </div>
                  <i className="fas fa-star star"></i>
                </div>
                <div className="progress_bar">
                  <ProgressBar now={accountProgress} />
                  <Badge className="progress_value">{accountProgress}%</Badge>
                </div>
                <div className="paragraph white no_margin">
                  {/* <VipLevelText identity={form.identity} /> */}
                </div>
              </div>
              <div className="item">
                <div className="input_label">
                  <i className="far fa-envelope"></i>
                  {__("Email")}
                </div>
                <input
                  disabled={true}
                  value={form.email}
                  placeholder={__("Email")}
                  className="form-controll input_box"
                  type="email"
                />
              </div>
              <div className="item">
                <div className="input_label">
                  <i className="fas fa-dollar-sign"></i>
                  {__("Currency")}
                </div>
                <input
                  disabled={true}
                  placeholder={__("Currency")}
                  className="form-controll input_box"
                  value={form.currency}
                  type="text"
                />
              </div>
            </div>
            <div className="single_block ">
              <div className="item">
                <div className="input_label">
                  <i class="fas fa-user"></i>
                  {__("First Name")}
                </div>
                <input
                  disabled={true}
                  placeholder={__("First Name")}
                  className="form-controll input_box"
                  value={form.firstName}
                  type="text"
                />
              </div>
              <div className="item">
                <div className="input_label">
                  <i class="fas fa-user"></i>
                  {__("Last Name")}
                </div>
                <input
                  disabled={true}
                  placeholder={__("Last Name")}
                  className="form-controll input_box"
                  value={form.lastName}
                  type="text"
                />
              </div>

              <div className="item">
                <div className="input_label">
                <i class="fas fa-user"></i>
                  {__("Username")}
                </div>
                <input
                  disabled={true}
                  placeholder={__("Username")}
                  className="form-controll input_box"
                  value={form.playerLogin}
                  type="text"
                />
              </div>
            </div>

            <div className="single_block ">
              <div className="item">
                <div className="input_label">
                  
                  {__("Occupation")}
                </div>
                <input
                  disabled={true}
                  placeholder={__("Occupation")}
                  className="form-controll input_box"
                  value={form.occupation}
                  type="text"
                />
              </div>
              <div className="item">
                <div className="input_label">
                  <svg
                    height="17"
                    width="17"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path d="M192 64c0-31 32-23 32-64 12 0 32 29.5 32 56s-14.25 40-32 40-32-14.25-32-32zm160 32c17.75 0 32-13.5 32-40S364 0 352 0c0 41-32 33-32 64 0 17.75 14.25 32 32 32zm96 176v240H0V272c0-26.5 21.5-48 48-48h24V112h48v112h80V112h48v112h80V112h48v112h24c26.5 0 48 21.5 48 48zm-400 6v56.831c8.352 7 15.27 13.169 26.75 13.169 25.378 0 30.13-32 74.75-32 43.974 0 49.754 32 74.5 32 25.588 0 30.061-32 74.75-32 44.473 0 49.329 32 74.75 32 11.258 0 18.135-6.18 26.5-13.187v-56.805a6 6 0 0 0-6-6L54 272a6 6 0 0 0-6 6zm352 186v-80.87c-7.001 2.914-15.54 4.87-26.5 4.87-44.544 0-49.389-32-74.75-32-25.144 0-30.329 32-74.75 32-43.974 0-49.755-32-74.5-32-25.587 0-30.062 32-74.75 32-11.084 0-19.698-1.974-26.75-4.911V464h352zM96 96c17.75 0 32-13.5 32-40S108 0 96 0c0 41-32 33-32 64 0 17.75 14.25 32 32 32z" />
                  </svg>
                  {__("Birthday")}
                </div>
                <input
                  disabled={true}
                  placeholder={__("Birthday")}
                  className="form-controll input_box"
                  value={form.dateOfBirth}
                  type="text"
                />
              </div>
              <div className="item">
                <div className="input_label">
                  <i className="fal fa-map-marked-alt"></i>
                  {__("Address")}
                </div>
                <input
                  disabled={true}
                  placeholder={__("Address")}
                  className="form-controll input_box"
                  value={form.address + (form.city ? ", " + form.city : "")}
                  type="text"
                />
              </div>
            </div>

            <div className="single_block ">
              <div className="item">
                <div className="input_label">
                  <i className="fas fa-globe-americas"></i>
                  {__("Country")}
                </div>
                <input
                  disabled={true}
                  placeholder={__("Country")}
                  className="form-controll input_box"
                  value={
                    countries?.filter((item) => item.value === form.country)[0]
                      ?.label
                  }
                  type="text"
                />
              </div>
              <div className="item">
                <div className="input_label">
                  <i className="far fa-flag"></i>
                  {__("States")}
                </div>
                <input
                  disabled={true}
                  placeholder={__("States")}
                  className="form-controll input_box"
                  value={form.state}
                  type="text"
                />
              </div>
              <div className="item">
                <div className="input_label">
                  <svg
                    height="17"
                    width="17"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path d="M592 96H240c-26.47 0-48 21.53-48 48v16h32v-16c0-8.84 7.16-16 16-16h352c8.84 0 16 7.16 16 16v224c0 8.84-7.16 16-16 16H416v32h176c26.47 0 48-21.53 48-48V144c0-26.47-21.53-48-48-48zM96 48c0-8.84 7.16-16 16-16h288c8.84 0 16 7.16 16 16v16h32V48c0-26.47-21.53-48-48-48H112C85.53 0 64 21.53 64 48v112h32V48zm464 208c8.84 0 16-7.16 16-16v-64c0-8.84-7.16-16-16-16h-64c-8.84 0-16 7.16-16 16v64c0 8.84 7.16 16 16 16h64zm-48-64h32v32h-32v-32zm-176 0H48c-26.47 0-48 21.53-48 48v224c0 26.47 21.53 48 48 48h288c26.47 0 48-21.53 48-48V240c0-26.47-21.53-48-48-48zm16 272c0 8.84-7.16 16-16 16H48c-8.84 0-16-7.16-16-16V313.6c12.8 9.6 32 25.6 96 70.4 12.8 9.6 38.4 32 64 32s51.2-22.4 64-32c64-44.8 83.2-60.8 96-70.4V464zm0-188.8c-25.6 19.2-22.4 19.2-115.2 86.4-9.6 3.2-28.8 22.4-44.8 22.4s-35.2-19.2-44.8-25.6C54.4 291.2 57.6 291.2 32 272v-32c0-8.84 7.16-16 16-16h288c8.84 0 16 7.16 16 16v35.2z" />
                  </svg>
                  {__("Zip Code")}
                </div>
                <input
                  disabled={true}
                  placeholder={__("Zip Code")}
                  className="form-controll input_box"
                  value={form.zip}
                  type="text"
                />
              </div>
            </div>

            {editMode && (
              <>
                <br />
                <br />
                {/* <h5 className="white">Change Password</h5> */}
                <h3 className="tabName white">{__("Change Password")}</h3>
                <div className="single_block ">
                  <div className="item">
                    <div className="input_label">
                      <i className="fas fa-unlock-alt"></i>
                      {__("Old Password")}
                    </div>
                    <InputBox
                      className="form-controll input_box"
                      value={form.oldPassword}
                      placeholder={__("Old Password")}
                      onChange={(value) => updateForm(value, "oldPassword")}
                      type="password"
                    />
                  </div>
                  <div className="item">
                    <div className="input_label">
                      <i className="fas fa-key"></i>
                      {__("Password")}
                    </div>
                    <InputBox
                      className="form-controll input_box"
                      value={form.password}
                      placeholder={__("Password")}
                      onChange={(value) => updateForm(value, "password")}
                      type="password"
                    />
                  </div>
                  <div className="item">
                    <div className="input_label">
                      <i className="far fa-key"></i>
                      {__("Re-type Password")}
                    </div>
                    <InputBox
                      className="form-controll input_box"
                      value={form.repassword}
                      placeholder={__("Re-type Password")}
                      onChange={(value) => updateForm(value, "repassword")}
                      type="password"
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button onClick={submitProfile} className="common_btn colortwoBg">
                    {__("Update")}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
