import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import emailValidate from "../services/emailValidate";
import { Modal, Button, Dropdown } from "react-bootstrap";
import { HeaderSection } from "../components/Navbar/NavbarElement";
import moment from "moment";

import HeaderWalletBalance from "pages/players/parts/HeaderWalletBalance";
import BuyGoldCoins from "./BuyGoldCoins";
import Translator, { __ } from "services/Translator";

const Header = (props) => {
  // const menuOpen = props.menuOpen;

  const { updateUserObj } = React.useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [userDetails, setUserDetails] = useState(auth.getUser);

  const logout = async () => {
    await auth.logout();
    await updateUserObj();
    setIsAuthenticated(auth.isAuthenticated);
  };

  // expand header before loging
  const [expandHeader, setExpandHeader] = useState(false);
  const toggleExpandHeader = () => {
    if (expandHeader === false) {
      setExpandHeader(true);
    } else {
      setExpandHeader(false);
    }
  };

  // sidebar
  const [showSidebar, setShowSidebar] = useState(false);
  const openSidebar = () => {
    setShowSidebar(true);
  };
  const closeSidebar = () => {
    setShowSidebar(false);
  };

  // currency setting
  const [currentCurrency, setCurrentCurrency] = useState("GC");
  const toggleCurrency = (ev) => {
    var target = ev.target;
    var checked = ev.target.checked;
    if (checked) {
      setCurrentCurrency("SC");
    } else {
      setCurrentCurrency("GC");
    }
  };

  // buymodal
  const [buyModal, setBuyModal] = useState(false);
  const openBuyModal = () => {
    setBuyModal(true);
  };
  const closeBuyModal = () => {
    setBuyModal(false);
  };

  // notification
  const [notificationModal, setNotificationModal] = useState(false);
  const toggleNotification = () => {
    if (notificationModal === false) {
      setNotificationModal(true);
    } else {
      setNotificationModal(false);
    }
  };

  // clock

  const [currentTime, setCurrentTime] = useState();
  const liveTime = () => {
    setCurrentTime(Date());
  };
  useEffect(() => {
    const interval = setInterval(() => {
      liveTime();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  //selected menu
  const [selectedMenu, setSelectedMenu] = useState("");
  const [showBuyModal, setShowBuyModal] = useState(false);

  return (
    <>
      <HeaderSection scrollNav={scrollNav}>
        <section className={expandHeader ? "header expand_mobile" : "header"}>
          <div className="clock">{moment(currentTime).format("LTS")}</div>
          <div className="container for_notification_relative">
            <div className="row justify-content-center align-self-center">
              <div
                className={
                  isAuthenticated
                    ? " col-4 col-sm-3 d-sm-block d-md-block d-lg-block col-md-3 col-lg-3"
                    : "d-block d-sm-block d-md-block col-4 col-sm-3 col-md-3 col-lg-3"
                }
              >
                <div className="logo d-none d-md-block">
                  {!isAuthenticated && (
                    <div className="d-inline d-sm-none d-md-none d-lg-none d-xl-none">
                      <Link onClick={toggleExpandHeader} to="#">
                        {expandHeader && <i className="fas fa-times"></i>}
                        {!expandHeader && <i className="fas fa-bars"></i>}
                      </Link>
                    </div>
                  )}
                  <Link to="/">
                    {props.themeMode == "dark" && (
                      <img className="solidImg" src={require("../assets/images/moolah-bucks-logo-white.svg").default} />
                    )}

                    {props.themeMode == "light" && (
                      <img
                        className="solidImg"
                        src={require("../assets/images/moolah-bucks-logo-dark-blue.svg").default}
                      />
                    )}

                    <img
                      className="hoverImg"
                      src={require("../assets/images/moolah-bucks-logo-dark-pink.svg").default}
                    />
                  </Link>
                </div>
                <div className="logo  d-md-none">
                  {!isAuthenticated && (
                    <div className="d-inline d-sm-none d-md-none d-lg-none d-xl-none">
                      <Link onClick={toggleExpandHeader} to="#">
                        {expandHeader && <i className="fas fa-times"></i>}
                        {!expandHeader && <i className="fas fa-bars"></i>}
                      </Link>
                    </div>
                  )}

                  <Link to="/">
                    <img
                      className="solidImg w-100"
                      src={require("../assets/images/moolah-bucks-logo-dark-blue-short.png").default}
                    />
                  </Link>
                </div>
              </div>
              <div
                className={
                  isAuthenticated
                    ? "col-8 col-sm-9 col-md-9 col-lg-9 align-self-center"
                    : "col-8 col-sm-9 col-md-9 col-lg-9 align-self-center"
                }
              >
                {isAuthenticated && (
                  <>
                    <div className="currency_section ">
                      <div className="currency-bar hud__primary-currency-bar currency-bar--goldBar d-none d-lg-block">
                        <div className="currency-bar__inner">
                          <div
                            className={
                              currentCurrency === "GC"
                                ? "currency-bar__currency-amount currency-bar__currency-amount--gold active"
                                : "currency-bar__currency-amount currency-bar__currency-amount--gold"
                            }
                          >
                            <div className="counter counter--GC">
                              <span className="counter__balance">
                                <b>GC</b>
                                <span className="counter__value"> 701,450</span>
                              </span>
                            </div>
                          </div>
                          <label
                            onChange={toggleCurrency}
                            className="currency-toggle currency-bar__currency-toggle toggle"
                            for="top-hud__currency-bar__currency-toggle"
                          >
                            <input type="checkbox" id="top-hud__currency-bar__currency-toggle" />
                            <span className="switch">
                              <span className="switch__slider"></span>
                              <span className="switch__fixed-icon--left"></span>
                              <span className="switch__fixed-icon--right"></span>
                            </span>
                          </label>
                          <div
                            className={
                              currentCurrency === "SC"
                                ? "currency-bar__currency-amount currency-bar__currency-amount--sweeps active"
                                : "currency-bar__currency-amount currency-bar__currency-amount--sweeps"
                            }
                          >
                            <div className="counter counter--SC">
                              <span className="counter__balance">
                                <b>SC</b> <span className="counter__value">0.00</span>
                              </span>
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              setShowBuyModal(true);
                              setTimeout(function () {
                                setShowBuyModal(false);
                              }, 500);
                            }}
                            class="currency-bar__buy-now"
                          >
                            +
                          </div>
                        </div>
                      </div>
                      <HeaderWalletBalance />

                      <div className="header_button_group">
                        <li className="d-none d-md-block">
                          <Link
                            onClick={() => {
                              setShowBuyModal(true);
                              setTimeout(function () {
                                setShowBuyModal(false);
                              }, 500);
                            }}
                            className="buy_btn"
                            to="#"
                          >
                            Buy
                          </Link>
                        </li>

                        {!props.isMobileDeviceByViewPort && (
                          <>
                            <li className={"notification_icon_container chat_menu_area "}>
                              <Dropdown>
                                <Dropdown.Toggle>
                                  <i className="far fa-comments"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <div className="modal_top_arrow"></div>
                                  <Dropdown.Item
                                    onClick={() => {
                                      props.setSupportSidebarOpen(true);
                                    }}
                                  >
                                    {__("Support Chat")}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      props.setIsChatVisible(true);
                                    }}
                                  >
                                    {__("Players Chat")}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </li>
                          </>
                        )}

                        {/* <li>
                          <Link
                            onClick={toggleNotification}
                            className="notification_btn notify-bell"
                            to="#"
                          >
                            <i className="fas fa-bell"></i>
                          </Link>
                        </li> */}
                        <li>
                          <Link onClick={openSidebar} className="notification_btn bars" to="#">
                            <i className="fas fa-bars"></i>
                          </Link>
                        </li>
                        <li className="themeModeSwitch">
                          <label class="switch">
                            <input
                              defaultChecked={props.themeMode == "dark" ? true : false}
                              onChange={(ev) => {
                                if (ev.target.checked) {
                                  ls.set("themeMode", "dark");
                                  props.setThemeMode("dark");
                                } else {
                                  ls.set("themeMode", "light");
                                  props.setThemeMode("light");
                                }
                              }}
                              type="checkbox"
                            />
                            <span class="slider round"></span>
                          </label>
                        </li>
                      </div>
                    </div>
                    {/* <div className="logged_menu">
                      <ul>
                        <li>
                          <Link to="/home">
                            <svg viewBox="0 0 40 40" fill="none">
                              <path
                                className="path"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M20.6076 0.264512C20.2001 -0.0895026 19.6088 -0.0880112 19.203 0.268056L0.391591 16.7724C-0.0720982 17.1792 -0.132656 17.9021 0.256331 18.387C0.645317 18.872 1.33655 18.9353 1.80024 18.5285L5.58906 15.2044V36.7908C5.58906 38.5632 6.96287 40 8.65755 40H31.3424C33.0371 40 34.4109 38.5632 34.4109 36.7908V15.2368L38.2038 18.532C38.6694 18.9365 39.3603 18.8697 39.7471 18.3828C40.1338 17.8959 40.0699 17.1733 39.6043 16.7688L20.6076 0.264512ZM32.2192 13.3326L19.9108 2.63913L7.78084 13.2814V36.7908C7.78084 37.2972 8.17335 37.7077 8.65755 37.7077H13.6986V22.5107C13.6986 21.8778 14.1893 21.3646 14.7945 21.3646H25.1246C25.7298 21.3646 26.2204 21.8778 26.2204 22.5107V37.7077H31.3424C31.8266 37.7077 32.2192 37.2972 32.2192 36.7908V13.3326ZM24.0287 23.6569V37.7077H15.8904V23.6569H24.0287Z"
                                fill="#F5F5F5"
                              ></path>
                            </svg>
                            Home
                          </Link>
                        </li>

                        <li>
                          <Link to="/games/slots">
                            <svg viewBox="0 0 40 40" fill="none">
                              <path
                                className="path"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M3.14607 0C1.40854 0 0 1.46193 0 3.26531V36.7347C0 38.5381 1.40854 40 3.14607 40H36.8539C38.5915 40 40 38.5381 40 36.7347V3.26531C40 1.46193 38.5915 0 36.8539 0H3.14607ZM2.24719 3.26531C2.24719 2.75006 2.64963 2.33236 3.14607 2.33236H36.8539C37.3504 2.33236 37.7528 2.75005 37.7528 3.26531V7.28863H2.24719V3.26531ZM26.9663 9.62099H37.7528V29.6793H26.9663V9.62099ZM25.2809 29.6793V9.62099H14.8315V29.6793H25.2809ZM13.1461 29.6793V9.62099H2.24719V29.6793H13.1461ZM2.24719 32.0117V36.7347C2.24719 37.2499 2.64963 37.6676 3.14607 37.6676H36.8539C37.3504 37.6676 37.7528 37.2499 37.7528 36.7347V32.0117H2.24719ZM9.21185 21.5067C8.97721 22.3031 8.8599 23.0253 8.8599 23.6735H6.0542C6.0542 23.0598 6.17813 22.3806 6.42598 21.636C6.67383 20.8913 6.98613 20.169 7.36286 19.4691C7.7396 18.7693 8.11964 18.1573 8.50299 17.6333H7.02579V18.5848H4.94382V15.6268H11.1699V17.1989C10.8064 17.7643 10.4478 18.4245 10.0942 19.1796C9.7406 19.9346 9.44648 20.7103 9.21185 21.5067ZM21.2112 21.5067C20.9765 22.3031 20.8592 23.0253 20.8592 23.6735H18.0535C18.0535 23.0598 18.1775 22.3806 18.4253 21.636C18.6732 20.8913 18.9855 20.169 19.3622 19.4691C19.7389 18.7693 20.119 18.1573 20.5023 17.6333H19.0251V18.5848H16.9431V15.6268H23.1692V17.1989C22.8057 17.7643 22.4471 18.4245 22.0935 19.1796C21.7399 19.9346 21.4458 20.7103 21.2112 21.5067ZM32.8586 23.6735C32.8586 23.0253 32.9759 22.3031 33.2105 21.5067C33.4451 20.7103 33.7393 19.9346 34.0929 19.1796C34.4465 18.4245 34.805 17.7643 35.1685 17.1989V15.6268H28.9425V18.5848H31.0244V17.6333H32.5016C32.1183 18.1573 31.7383 18.7693 31.3615 19.4691C30.9848 20.169 30.6725 20.8913 30.4246 21.636C30.1768 22.3806 30.0529 23.0598 30.0529 23.6735H32.8586Z"
                                fill="#F5F5F5"
                              ></path>
                            </svg>
                            Slots
                          </Link>
                        </li>
                        <li>
                          <Link to="/games/jackpot">
                            <svg viewBox="0 0 40 40" fill="none">
                              <path
                                className="path"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M24.5636 0.973426H20.3452H16.6513C16.6358 0.97305 16.6203 0.973052 16.6048 0.973426H11.3505C11.0351 0.973426 10.7365 1.12688 10.5373 1.3913L1.06877 13.9587C0.752828 14.378 0.754611 14.9802 1.07302 15.3974L19.5226 39.5675C19.5475 39.6014 19.5744 39.6341 19.6034 39.6653C19.6901 39.7593 19.7892 39.8338 19.8954 39.8882C20.0381 39.9616 20.192 39.9976 20.3452 39.9975C20.4984 39.9976 20.6523 39.9616 20.795 39.8882C20.9012 39.8338 21.0003 39.7593 21.087 39.6653C21.116 39.6341 21.1429 39.6014 21.1678 39.5675L39.6174 15.3974C39.9358 14.9802 39.9376 14.378 39.6216 13.9587L30.1531 1.3913C29.9539 1.12688 29.6553 0.973426 29.3399 0.973426H24.6102C24.5947 0.973052 24.5791 0.97305 24.5636 0.973426ZM23.9083 3.24227H20.3452H17.3067L13.0935 13.4007H28.1214L23.9083 3.24227ZM30.4209 13.4007L26.2077 3.24227H28.8414L36.4949 13.4007H30.4209ZM28.2478 15.6695H12.9672L20.6075 35.2554L28.2478 15.6695ZM24.0822 32.1854L30.5249 15.6695H36.6891L24.0822 32.1854ZM4.19546 13.4007H10.794L15.0072 3.24227H11.849L4.19546 13.4007ZM17.681 33.5908L10.6901 15.6695H4.00128L17.681 33.5908Z"
                                fill="#F5F5F5"
                              ></path>
                            </svg>
                            jackpot
                          </Link>
                        </li>
                        <li>
                          <Link to="/games/table-games">
                            <svg viewBox="0 0 40 40" fill="none">
                              <path
                                className="path"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.10533 6.92261C0.445369 7.51632 -0.41051 9.31996 0.193665 10.9512L10.1867 37.9311C10.7908 39.5623 12.6263 40.4034 14.2862 39.8097L33.1087 33.0776C34.7686 32.4838 35.6245 30.6802 35.0203 29.049L25.0273 2.06903C24.4231 0.437827 22.5877 -0.403228 20.9277 0.190481L2.10533 6.92261ZM2.32531 10.1887C2.14963 9.71443 2.3985 9.18996 2.88118 9.01732L21.7036 2.2852C22.1863 2.11256 22.72 2.35712 22.8957 2.83144L32.8887 29.8114C33.0644 30.2857 32.8155 30.8102 32.3328 30.9828L13.5104 37.715C13.0277 37.8876 12.494 37.643 12.3183 37.1687L2.32531 10.1887ZM18.316 22.7981C18.2299 23.8697 17.7979 25.4339 16.0889 26.0452C13.3799 27.0141 10.7494 25.4421 10.0138 21.8927C9.27816 18.3434 14.2889 10.8877 14.2889 10.8877C14.2889 10.8877 23.0042 13.4341 24.7623 16.6177C26.5204 19.8013 25.5337 22.6671 22.8247 23.636C21.1157 24.2473 19.7615 23.3219 18.9947 22.5554C19.6059 23.4009 20.4904 24.5306 21.4406 25.4359C23.1951 27.1076 25.8402 27.3945 25.8402 27.3945L21.0388 29.1118L16.2374 30.8291C16.2374 30.8291 18.076 28.9385 18.3266 26.5497C18.4623 25.256 18.4008 23.8319 18.316 22.7981Z"
                                fill="#F5F5F5"
                              ></path>
                              <mask
                                id="mask0"
                                mask-type="alpha"
                                maskUnits="userSpaceOnUse"
                                x="19"
                                y="2"
                                width="21"
                                height="37"
                              >
                                <path
                                  className="path"
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M24.6122 2.14746L23.4115 2.14746L33.4676 29.1119V31.7803L25.66 34.7697L19.4785 36.7884L20.6031 37.8896L35.7883 38.5018L38.4201 36.7884L39.2698 28.3396L40 4.5329L37.1347 2.14746H25.8401L24.6122 2.14746Z"
                                  fill="white"
                                ></path>
                              </mask>
                              <g mask="url(#mask0)">
                                <path
                                  className="path"
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M12.9645 5.19978C13.0262 3.46495 14.5073 2.1077 16.2727 2.16829L36.2909 2.85523C38.0563 2.91581 39.4375 4.37128 39.3759 6.10611L38.3562 34.8001C38.2945 36.5349 36.8134 37.8922 35.048 37.8316L15.0298 37.1446C13.2644 37.0841 11.8832 35.6286 11.9449 33.8938L12.9645 5.19978ZM16.1936 4.39608C15.6802 4.37846 15.2495 4.77312 15.2316 5.27758L14.2119 33.9716C14.194 34.476 14.5956 34.8992 15.109 34.9169L35.1272 35.6038C35.6405 35.6214 36.0712 35.2267 36.0891 34.7223L37.1088 6.02831C37.1267 5.52386 36.7251 5.10064 36.2118 5.08302L16.1936 4.39608Z"
                                  fill="#F5F5F5"
                                ></path>
                              </g>
                            </svg>
                            TABLE GAMES
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                  </>
                )}
                {!isAuthenticated && (
                  <>
                    <div className="menus">
                      <ul>
                        <li className="d-none d-sm-block d-md-block">
                          <Link
                            onClick={() => {
                              setSelectedMenu("games");
                            }}
                            className={selectedMenu == "games" ? "active" : ""}
                            to="/home"
                          >
                            Games
                          </Link>
                        </li>
                        <li className="d-none d-sm-block d-md-block">
                          <Link
                            onClick={() => {
                              setSelectedMenu("about");
                            }}
                            className={selectedMenu == "about" ? "active" : ""}
                            to="/about-us"
                          >
                            About
                          </Link>
                        </li>
                        <li className="d-none d-sm-block d-md-block">
                          <Link
                            onClick={() => {
                              setExpandHeader(false);
                              props.setSupportSidebarOpen(true);
                            }}
                          >
                            Contact
                          </Link>
                        </li>

                        <li>
                          <Link
                            onClick={() => {
                              setExpandHeader(false);
                              setSelectedMenu("login");
                            }}
                            className={selectedMenu == "login" ? "active" : ""}
                            // onClick={() => {
                            //   setIsloggedIn(true);
                            // }}
                            // className="active"
                            to="/login"
                          >
                            Login
                          </Link>
                        </li>
                        <li className="themeModeSwitch">
                          <label class="switch">
                            <input
                              defaultChecked={props.themeMode == "dark" ? true : false}
                              onChange={(ev) => {
                                if (ev.target.checked) {
                                  ls.set("themeMode", "dark");
                                  props.setThemeMode("dark");
                                } else {
                                  ls.set("themeMode", "light");
                                  props.setThemeMode("light");
                                }
                              }}
                              type="checkbox"
                            />
                            <span class="slider round"></span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* if expand */}
            {expandHeader && (
              <div className="row">
                <div className="menus">
                  <ul className="center_align">
                    <li>
                      <Link
                        onClick={() => {
                          setExpandHeader(false);
                        }}
                        to="/home"
                      >
                        Games
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setExpandHeader(false);
                        }}
                        to="/about-us"
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setExpandHeader(false);
                          props.setSupportSidebarOpen(true);
                        }}
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {notificationModal && (
              <>
                <div class="notification-hud">
                  <h5>Notifications</h5>
                  <div class="notification-hud__scrollable">
                    <ul class="list-unstyled">
                      <li>
                        <Link
                          onClick={() => {
                            setNotificationModal(false);
                          }}
                          target="_blank"
                          to="#"
                        >
                          <div class="notification-hud__element actioned">
                            <div class="notification-hud__element-inner">
                              <div class="notification-hud__element-icon">
                                <img src={require("../assets/images/icons/notification-icon.svg").default} />
                              </div>
                              <div class="notification-hud__element-copy">
                                <h6>Customize your activity reminder!</h6>
                                <p class="message-body">
                                  You can now adjust your activity reminder interval. Adjust here.
                                </p>
                                <p class="timestamp">3 months ago</p>
                              </div>
                              <div class="notification-hud__element-dot-container"></div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>

          {showSidebar && (
            <div className="custom_sidebar">
              <div id="mySidenav" className="sidenav">
                <div className="sidebar_logo">
                  <Link
                    onClick={() => {
                      setShowSidebar(false);
                    }}
                    to="/"
                  >
                    <img width={100} src={require("../assets/images/moolah-bucks-logo-white.svg").default} />
                  </Link>
                </div>
                <Link
                  // to="javascript:void(0)"
                  className="closebtn"
                  onClick={closeSidebar}
                >
                  &times;
                </Link>

                <div className="logged_menu">
                  <ul className="in_sidebar">
                    <li className="no_hover">
                      <div className="currency_section">
                        <div
                          class="base-button btn-link side-bar__item side-bar__switch-currency-item sidebar_currency_btn"
                          id="side-bar__switch-currency"
                        >
                          <label
                            onChange={toggleCurrency}
                            class="currency-toggle side-bar__currency-toggle toggle "
                            for="currency-bar__currency-toggle"
                          >
                            <input type="checkbox" id="currency-bar__currency-toggle" />
                            <span class="switch">
                              <span class="switch__slider"></span>
                              <span class="switch__fixed-icon--left"></span>
                              <span class="switch__fixed-icon--right"></span>
                            </span>
                          </label>
                          <span className="currency_name">
                            {currentCurrency === "GC" && "Gold"}
                            {currentCurrency === "SC" && "Sweeps"}
                          </span>
                        </div>
                      </div>
                    </li>

                    <li className="button_group_sidebar">
                      <Link
                        onClick={() => {
                          setShowBuyModal(true);
                          setTimeout(function () {
                            setShowBuyModal(false);
                          }, 500);
                        }}
                        className="buy_btn"
                        to="#"
                      >
                        buy
                      </Link>
                      <Link className="buy_btn no_bg" to="#">
                        REDEEM
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" id="status-icon__unverified">
                          <g filter="url(#filter0_d)">
                            <circle cx="11" cy="9" r="9" fill="#FF2626"></circle>
                            <circle cx="11" cy="9" r="8.5" stroke="#FD6E6E"></circle>
                          </g>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13.2 7.05263H11.0239H10.7942H8.61818V6.00429C8.61818 4.8489 9.6464 3.89474 10.9094 3.89474C12.1718 3.89474 13.2 4.8489 13.2 6.00429V7.05263ZM11.6139 10.6894V12.3806C11.6139 12.5727 11.4631 12.7368 11.2822 12.7368H10.5365C10.3551 12.7368 10.2048 12.5727 10.2048 12.3806V10.6894C10.029 10.5061 9.92727 10.2495 9.92727 9.96768C9.92727 9.43147 10.3132 8.97021 10.8053 8.94942C10.857 8.94668 10.9612 8.94668 11.0134 8.94942C11.505 8.97021 11.8909 9.43147 11.8909 9.96768C11.8909 10.2495 11.7892 10.5061 11.6139 10.6894ZM15.1223 6.84552H14.8722V5.7514C14.8722 3.72806 13.1635 2.03612 11.0255 2.00121C10.9672 1.9996 10.8504 1.9996 10.7926 2.00121C8.65469 2.03612 6.94537 3.72806 6.94537 5.7514V6.84552H6.69586C6.31188 6.84552 6 7.21775 6 7.67752V13.1664C6 13.6251 6.31188 14 6.69586 14H15.1223C15.5063 14 15.8182 13.6251 15.8182 13.1664V7.67752C15.8182 7.21775 15.5063 6.84552 15.1223 6.84552Z"
                            fill="white"
                          ></path>
                          <defs>
                            <filter
                              id="filter0_d"
                              x="0"
                              y="0"
                              width="22"
                              height="22"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                            >
                              <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              ></feColorMatrix>
                              <feOffset dy="2"></feOffset>
                              <feGaussianBlur stdDeviation="1"></feGaussianBlur>
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.0509804 0 0 0 0 0.113725 0 0 0 0 0.196078 0 0 0 0.4 0"
                              ></feColorMatrix>
                              <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow"
                                result="shape"
                              ></feBlend>
                            </filter>
                          </defs>
                        </svg>
                      </Link>
                    </li>
                    {isAuthenticated && (
                      <li>
                        <Link to="/dashboard/account-details">My Account</Link>
                      </li>
                    )}
                    <li>
                      <Link
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                        to="/home"
                      >
                        <svg viewBox="0 0 40 40" fill="none">
                          <path
                            className="path"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20.6076 0.264512C20.2001 -0.0895026 19.6088 -0.0880112 19.203 0.268056L0.391591 16.7724C-0.0720982 17.1792 -0.132656 17.9021 0.256331 18.387C0.645317 18.872 1.33655 18.9353 1.80024 18.5285L5.58906 15.2044V36.7908C5.58906 38.5632 6.96287 40 8.65755 40H31.3424C33.0371 40 34.4109 38.5632 34.4109 36.7908V15.2368L38.2038 18.532C38.6694 18.9365 39.3603 18.8697 39.7471 18.3828C40.1338 17.8959 40.0699 17.1733 39.6043 16.7688L20.6076 0.264512ZM32.2192 13.3326L19.9108 2.63913L7.78084 13.2814V36.7908C7.78084 37.2972 8.17335 37.7077 8.65755 37.7077H13.6986V22.5107C13.6986 21.8778 14.1893 21.3646 14.7945 21.3646H25.1246C25.7298 21.3646 26.2204 21.8778 26.2204 22.5107V37.7077H31.3424C31.8266 37.7077 32.2192 37.2972 32.2192 36.7908V13.3326ZM24.0287 23.6569V37.7077H15.8904V23.6569H24.0287Z"
                            fill="#F5F5F5"
                          ></path>
                        </svg>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                        to="/games/slots"
                      >
                        <svg viewBox="0 0 40 40" fill="none">
                          <path
                            className="path"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3.14607 0C1.40854 0 0 1.46193 0 3.26531V36.7347C0 38.5381 1.40854 40 3.14607 40H36.8539C38.5915 40 40 38.5381 40 36.7347V3.26531C40 1.46193 38.5915 0 36.8539 0H3.14607ZM2.24719 3.26531C2.24719 2.75006 2.64963 2.33236 3.14607 2.33236H36.8539C37.3504 2.33236 37.7528 2.75005 37.7528 3.26531V7.28863H2.24719V3.26531ZM26.9663 9.62099H37.7528V29.6793H26.9663V9.62099ZM25.2809 29.6793V9.62099H14.8315V29.6793H25.2809ZM13.1461 29.6793V9.62099H2.24719V29.6793H13.1461ZM2.24719 32.0117V36.7347C2.24719 37.2499 2.64963 37.6676 3.14607 37.6676H36.8539C37.3504 37.6676 37.7528 37.2499 37.7528 36.7347V32.0117H2.24719ZM9.21185 21.5067C8.97721 22.3031 8.8599 23.0253 8.8599 23.6735H6.0542C6.0542 23.0598 6.17813 22.3806 6.42598 21.636C6.67383 20.8913 6.98613 20.169 7.36286 19.4691C7.7396 18.7693 8.11964 18.1573 8.50299 17.6333H7.02579V18.5848H4.94382V15.6268H11.1699V17.1989C10.8064 17.7643 10.4478 18.4245 10.0942 19.1796C9.7406 19.9346 9.44648 20.7103 9.21185 21.5067ZM21.2112 21.5067C20.9765 22.3031 20.8592 23.0253 20.8592 23.6735H18.0535C18.0535 23.0598 18.1775 22.3806 18.4253 21.636C18.6732 20.8913 18.9855 20.169 19.3622 19.4691C19.7389 18.7693 20.119 18.1573 20.5023 17.6333H19.0251V18.5848H16.9431V15.6268H23.1692V17.1989C22.8057 17.7643 22.4471 18.4245 22.0935 19.1796C21.7399 19.9346 21.4458 20.7103 21.2112 21.5067ZM32.8586 23.6735C32.8586 23.0253 32.9759 22.3031 33.2105 21.5067C33.4451 20.7103 33.7393 19.9346 34.0929 19.1796C34.4465 18.4245 34.805 17.7643 35.1685 17.1989V15.6268H28.9425V18.5848H31.0244V17.6333H32.5016C32.1183 18.1573 31.7383 18.7693 31.3615 19.4691C30.9848 20.169 30.6725 20.8913 30.4246 21.636C30.1768 22.3806 30.0529 23.0598 30.0529 23.6735H32.8586Z"
                            fill="#F5F5F5"
                          ></path>
                        </svg>
                        Slots
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                        to="/games/jackpot"
                      >
                        <svg viewBox="0 0 40 40" fill="none">
                          <path
                            className="path"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M24.5636 0.973426H20.3452H16.6513C16.6358 0.97305 16.6203 0.973052 16.6048 0.973426H11.3505C11.0351 0.973426 10.7365 1.12688 10.5373 1.3913L1.06877 13.9587C0.752828 14.378 0.754611 14.9802 1.07302 15.3974L19.5226 39.5675C19.5475 39.6014 19.5744 39.6341 19.6034 39.6653C19.6901 39.7593 19.7892 39.8338 19.8954 39.8882C20.0381 39.9616 20.192 39.9976 20.3452 39.9975C20.4984 39.9976 20.6523 39.9616 20.795 39.8882C20.9012 39.8338 21.0003 39.7593 21.087 39.6653C21.116 39.6341 21.1429 39.6014 21.1678 39.5675L39.6174 15.3974C39.9358 14.9802 39.9376 14.378 39.6216 13.9587L30.1531 1.3913C29.9539 1.12688 29.6553 0.973426 29.3399 0.973426H24.6102C24.5947 0.973052 24.5791 0.97305 24.5636 0.973426ZM23.9083 3.24227H20.3452H17.3067L13.0935 13.4007H28.1214L23.9083 3.24227ZM30.4209 13.4007L26.2077 3.24227H28.8414L36.4949 13.4007H30.4209ZM28.2478 15.6695H12.9672L20.6075 35.2554L28.2478 15.6695ZM24.0822 32.1854L30.5249 15.6695H36.6891L24.0822 32.1854ZM4.19546 13.4007H10.794L15.0072 3.24227H11.849L4.19546 13.4007ZM17.681 33.5908L10.6901 15.6695H4.00128L17.681 33.5908Z"
                            fill="#F5F5F5"
                          ></path>
                        </svg>
                        jackpot
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                        to="/games/table-games"
                      >
                        <svg viewBox="0 0 40 40" fill="none">
                          <path
                            className="path"
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.10533 6.92261C0.445369 7.51632 -0.41051 9.31996 0.193665 10.9512L10.1867 37.9311C10.7908 39.5623 12.6263 40.4034 14.2862 39.8097L33.1087 33.0776C34.7686 32.4838 35.6245 30.6802 35.0203 29.049L25.0273 2.06903C24.4231 0.437827 22.5877 -0.403228 20.9277 0.190481L2.10533 6.92261ZM2.32531 10.1887C2.14963 9.71443 2.3985 9.18996 2.88118 9.01732L21.7036 2.2852C22.1863 2.11256 22.72 2.35712 22.8957 2.83144L32.8887 29.8114C33.0644 30.2857 32.8155 30.8102 32.3328 30.9828L13.5104 37.715C13.0277 37.8876 12.494 37.643 12.3183 37.1687L2.32531 10.1887ZM18.316 22.7981C18.2299 23.8697 17.7979 25.4339 16.0889 26.0452C13.3799 27.0141 10.7494 25.4421 10.0138 21.8927C9.27816 18.3434 14.2889 10.8877 14.2889 10.8877C14.2889 10.8877 23.0042 13.4341 24.7623 16.6177C26.5204 19.8013 25.5337 22.6671 22.8247 23.636C21.1157 24.2473 19.7615 23.3219 18.9947 22.5554C19.6059 23.4009 20.4904 24.5306 21.4406 25.4359C23.1951 27.1076 25.8402 27.3945 25.8402 27.3945L21.0388 29.1118L16.2374 30.8291C16.2374 30.8291 18.076 28.9385 18.3266 26.5497C18.4623 25.256 18.4008 23.8319 18.316 22.7981Z"
                            fill="#F5F5F5"
                          ></path>
                          <mask
                            id="mask0"
                            mask-type="alpha"
                            maskUnits="userSpaceOnUse"
                            x="19"
                            y="2"
                            width="21"
                            height="37"
                          >
                            <path
                              className="path"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M24.6122 2.14746L23.4115 2.14746L33.4676 29.1119V31.7803L25.66 34.7697L19.4785 36.7884L20.6031 37.8896L35.7883 38.5018L38.4201 36.7884L39.2698 28.3396L40 4.5329L37.1347 2.14746H25.8401L24.6122 2.14746Z"
                              fill="white"
                            ></path>
                          </mask>
                          <g mask="url(#mask0)">
                            <path
                              className="path"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M12.9645 5.19978C13.0262 3.46495 14.5073 2.1077 16.2727 2.16829L36.2909 2.85523C38.0563 2.91581 39.4375 4.37128 39.3759 6.10611L38.3562 34.8001C38.2945 36.5349 36.8134 37.8922 35.048 37.8316L15.0298 37.1446C13.2644 37.0841 11.8832 35.6286 11.9449 33.8938L12.9645 5.19978ZM16.1936 4.39608C15.6802 4.37846 15.2495 4.77312 15.2316 5.27758L14.2119 33.9716C14.194 34.476 14.5956 34.8992 15.109 34.9169L35.1272 35.6038C35.6405 35.6214 36.0712 35.2267 36.0891 34.7223L37.1088 6.02831C37.1267 5.52386 36.7251 5.10064 36.2118 5.08302L16.1936 4.39608Z"
                              fill="#F5F5F5"
                            ></path>
                          </g>
                        </svg>
                        TABLE GAMES
                      </Link>
                    </li>
                  </ul>
                </div>
                <hr></hr>
                <div className="custom_sidebar_body">
                  <ul>
                    <li>
                      <button className="toggle-button toggle_button" id="side-bar__sound-toggle-btn">
                        Sound
                        <label className="sound-toggle toggle " for="sound-toggle">
                          <input type="checkbox" id="sound-toggle" checked="on" />
                          <span className="switch">
                            <span className="switch__slider"></span>
                            <span className="switch__fixed-icon--left"></span>
                            <span className="switch__fixed-icon--right"></span>
                          </span>
                        </label>
                        <b>ON</b>
                      </button>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                        className="link active"
                        to="/gameplay-history"
                      >
                        Gameplay History
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                        className="link active"
                        to="/history"
                      >
                        Purchase & Prize History
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                        className="link active"
                        to="#"
                      >
                        Activity Reminder
                      </Link>
                    </li>
                    <hr></hr>
                    <li>
                      <Link
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                        className="link active"
                        to="/terms-and-conditions"
                      >
                        Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                        className="link"
                        to="/privacy-policy"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                        className="link"
                        to="#"
                      >
                        Sweeps Rules
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setShowSidebar(false);
                        }}
                        className="link"
                        to="/responsible-social-gameplay-policy"
                      >
                        Responsible Social Gameplay
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => {
                          setExpandHeader(false);
                          props.setSupportSidebarOpen(true);
                        }}
                        className="link"
                      >
                        Contact Us
                      </Link>
                    </li>
                    <hr></hr>
                    <li className="logout">
                      <Link onClick={logout} className="link" to="#">
                        <i className="fal fa-power-off menu_icon"></i> Log Out
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </section>
      </HeaderSection>

      <BuyGoldCoins showBuyModal={showBuyModal} />
    </>
  );
};

export default Header;
