import React, { useEffect, useState } from "react";
import { Button, Alert, Collapse } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import emailValidate from "../../services/emailValidate";
import validator from "validator";
import swal from "sweetalert";
import api from "../../services/api";
import auth from "../../services/auth";
// import Spinner from "../elements/Spinner";
import AppContext from "contexts/AppContext";
import jwt_decode from "jwt-decode";

import FacebookLogin from "react-facebook-login";
import AppleLogin from "react-apple-login";
// import GoogleLogin from "react-google-login";

import SocialRegister from "./SocialRegister";
import SocialLogin from "./SocialLogin";

export default function Login(props) {
  const history = useHistory();

  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully added",
    variant: "success",
    showAlert: false,
  });

  const [errors, setErrors] = useState({});
  // const params = queryString.parse(window.location.search).p;

  //Auth State
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const { updateUserObj } = React.useContext(AppContext);

  const [mainErrorMgs, setMainErrorMgs] = useState("");
  const [spinner, setSpinner] = useState(false);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [acceptField, setAcceptField] = useState({
    email: false,
    password: false,
  });

  const loginValidateForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    // alert(44);
    if (loginData.email < 2 || loginData.email.length > 250) {
      errorsTmp = { ...errorsTmp, email: "Please enter your email" };
      valid = false;
    }
    if (loginData.password < 2 || loginData.password.length > 250) {
      errorsTmp = { ...errorsTmp, password: "Please enter password" };
      valid = false;
    }
    if (!emailValidate(loginData.email)) {
      errorsTmp = { ...errorsTmp, email: "Your email not valid" };
      valid = false;
    }
    setErrors(errorsTmp);
    return valid;
  };

  const updateLoginForm = (field, value) => {
    setLoginData({ ...loginData, [field]: value });
  };

  const loginInputChange = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    if (field == "email") {
      if (emailValidate(value)) {
        setAcceptField({ ...acceptField, email: true });
        setErrors({ ...errors, email: "" });
      } else {
        setAcceptField({ ...acceptField, email: false });
        setErrors({ ...errors, email: "Your email not valid" });
      }
    }
    if (field == "password") {
      if (
        validator.isStrongPassword(value, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setAcceptField({ ...acceptField, password: true });
        setErrors({ ...errors, password: "" });
      } else {
        setAcceptField({ ...acceptField, password: false });
        setErrors({
          ...errors,
          password:
            "Password at least 8-30 characters,  one number, one special character, one lowercase and one upercase. Don't use email address or username.",
        });
      }
    }

    updateLoginForm(field, value);
  };

  const loginActionBAK = () => {
    var valid = loginValidateForm();
    if (valid) {
      swal({
        title: "Login Success!",
        icon: "success",
      });

      history.push("/home");
    }
  };

  const loginAction = async (ev) => {
    ev.preventDefault();
    var valid = loginValidateForm();
    setMainErrorMgs("");
    if (valid) {
      setSpinner(true);
      var response = await api.post("/login", loginData);
      console.log(response);
      setSpinner(false);
      if (response.status == 200) {
        await auth.login(response.data.user);
        await updateUserObj(response.data.user);
        // setRegistrationSuccessModal(true);
        // setShowLoginModal(false);
        // setOpenModal(false);
        setIsAuthenticated(auth.isAuthenticated);
      } else {
        setAlertMsg({
          message: response.data.message,
          variant: "danger",
          showAlert: true,
        });

        setTimeout(() => {
          setAlertMsg({
            message: response.data.message,
            variant: "success",
            showAlert: false,
          });
        }, 7000);
      }
    }
  };

  //social functionality
  const [showSocialRegTerms, setShowSocialRegTerms] = useState(false);
  const [socialRegisterData, setSocialRegisterData] = useState({
    name: "",
    email: "",
    socialId: "",
    provider: "",
    agreeToTerms: 0,
    acceptCountryLimitation: 0,
  });

  const responseFacebook = (response) => {
    console.log("responseFacebook", response);
    if (response.status != "unknown") {
      setSocialRegisterData({
        name: response.name,
        email: response.email,
        socialId: response.userID,
        provider: "Facebook",
        agreeToTerms: 0,
        acceptCountryLimitation: 0,
      });
    }
    // setSpinner(false);
  };

  const componentClicked = (response) => {
    console.log(response);
    setSpinner(true);
  };

  const successResponseGoogle = (response) => {
    setSocialRegisterData({
      name: response.profileObj.name,
      email: response.profileObj.email,
      socialId: response.profileObj.googleId,
      provider: "Google",
      agreeToTerms: 0,
      acceptCountryLimitation: 0,
    });
    // setSpinner(false);
    console.log("successResponseGoogle", response);
    console.log("socialRegisterData", socialRegisterData);
  };

  const failureResponseGoogle = (response) => {
    console.log("failureResponseGoogle", response);
    setSpinner(false);
  };

  const [socialLoginData, setSocialLoginData] = useState({});
  const [applyLogin, setApplyLogin] = useState(false);
  const checkAvailability = async (ev) => {
    var response = await api.post("/check-social-availability", socialRegisterData);

    console.log(response.data);
    if (response.data.status == "Not Found") {
      setShowSocialRegTerms(true);
    } else if (response.data.status == "Already Registered") {
      // setShowSocialRegTerms(true);

      setSocialLoginData({
        email: socialRegisterData.email,
        socialId: socialRegisterData.socialId,
        provider: socialRegisterData.provider,
      });
      setApplyLogin(true);
    } else if (response.data.status == "Email Already Registered") {
      setShowSocialRegTerms(true);
    } else {
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (socialRegisterData.provider != "") {
      checkAvailability();
    }
  }, [socialRegisterData]);

  const [initialSettings, setInitialSettings] = useState({
    facebook_login_enabled: 0,
    google_login_enabled: 0,
    apple_login_enabled: 0,
  });
  const getInitialSettings = async (ev) => {
    var response = await api.get("/initial-settings");
    setInitialSettings(response.data);
  };

  useEffect(() => {
    getInitialSettings();
  }, []);

  const [showHideLogin, setShowHideLogin] = useState(false);
  const [open, setOpen] = useState(false);

  //New google login functionality
  const responseGoogle = (response) => {
    // console.log(response);
    var userObject = jwt_decode(response.credential);
    // console.log(userObject.name);
    setSocialRegisterData({
      name: userObject.name,
      email: userObject.email,
      socialId: userObject.sub,
      provider: "Google",
      agreeToTerms: 0,
      acceptCountryLimitation: 0,
    });

    props.setSpinner(false);
  };

  useEffect(() => {
    if (window.google !== undefined) {
      window.google.accounts.id.initialize({
        client_id: "181365605324-ouu33ortleiauap1vhmqo5fh2o1b81l5.apps.googleusercontent.com",
        callback: responseGoogle,
      });

      window.google.accounts.id.renderButton(document.getElementById("googleLogin"), {
        theme: "outline",
        // width: "318",
      });
    }
  }, [window.google]);

  const handleGoogleLogin = () => {
    document.getElementById("googleLogin").querySelector("div[role=button]").click();
  };

  return (
    <>
      <div className="box_container">
        {applyLogin && <SocialLogin loginData={socialLoginData} />}

        {showSocialRegTerms && <SocialRegister socialRegisterData={socialRegisterData} />}
        {!showSocialRegTerms && (
          <div className="register">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-10 col-md-8 col-lg-4 offset-sm-1 offset-md-2 offset-lg-4">
                  <div className="form_area login_area">
                    <div className="common_headline text-center login_heading">Login</div>
                    <div className="login_button">
                      <Alert show={alertMsg.showAlert} variant={alertMsg.variant}>
                        {alertMsg.message}
                      </Alert>
                      {/* <Button
                  onClick={componentClicked}
                  className="login_btn facebook_btn"
                >
                  <i class="fab fa-facebook-square facebook_icon"></i> Sign Up
                  with Facebook
                </Button> */}
                      {initialSettings.facebook_login_enabled == true && (
                        <>
                          <FacebookLogin
                            appId={process.env.REACT_APP_FB_APP_ID || ""}
                            autoLoad={false}
                            fields="name,email,picture"
                            textButton="Login with Facebook"
                            callback={responseFacebook}
                            onClick={componentClicked}
                            cssClass="login_btn facebook_btn"
                            icon="fab fa-facebook-square facebook_icon"
                          ></FacebookLogin>
                          &nbsp;
                          {/* <div className="input_item">
                          <div className="name or_text text-center">or</div>
                        </div> */}
                        </>
                      )}

                      {initialSettings.google_login_enabled == true && (
                        <>
                          {/* <GoogleLogin
                            clientId={process.env.REACT_APP_GG_APP_ID || ""}
                            buttonText="Login"
                            onSuccess={successResponseGoogle}
                            onFailure={failureResponseGoogle}
                            className="login_btn google_btn"
                            buttonText="Login with Google"
                            cookiePolicy={"single_host_origin"}
                          /> */}
                          <button
                            type="button"
                            className="showHideLogin login_btn default_btn"
                            style={{ background: "white", color: "black" }}
                            onClick={handleGoogleLogin}
                          >
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 48 48"
                              class="LgbsSe-Bz112c"
                              style={{ width: 20, height: 20, float: "left", marginTop: 5, marginLeft: 8 }}
                            >
                              <g>
                                <path
                                  fill="#EA4335"
                                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                                ></path>
                                <path
                                  fill="#4285F4"
                                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                                ></path>
                                <path
                                  fill="#FBBC05"
                                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                                ></path>
                                <path
                                  fill="#34A853"
                                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                                ></path>
                                <path fill="none" d="M0 0h48v48H0z"></path>
                              </g>
                            </svg>
                            Login With Google
                          </button>
                          <div id="googleLogin" className="googleLoginButton" style={{ visibility: "hidden" }}></div>
                          &nbsp;
                          {/* <div className="input_item">
                      <div className="name or_text text-center">or</div>
                    </div>
                    <AppleLogin
                      clientId="com.react.apple.login"
                      redirectURI="https://redirectUrl.com"
                    /> */}
                          {/* <div className="input_item">
                          <div className="name or_text text-center">or</div>
                        </div> */}
                        </>
                      )}
                      <button
                        type="button"
                        className="showHideLogin login_btn default_btn"
                        onClick={() => setOpen(!open)}
                      >
                        <i class="fa fa-envelope email_icon" aria-hidden="true"></i>
                        Login With Email
                      </button>
                    </div>
                    <br />
                    <Collapse in={open}>
                      <div id="example-collapse-text">
                        <form className={"loginFormArea  "} onSubmit={loginAction}>
                          <div className="input_item">
                            <input
                              onChange={loginInputChange}
                              type="email"
                              name="email"
                              className={
                                acceptField.email ? "form-control name_value border_success" : "form-control name_value"
                              }
                              placeholder="Your email"
                              value={loginData.email}
                            />
                            <div className="error_msg">{errors.email}</div>
                          </div>
                          <div className="input_item">
                            <input
                              onChange={loginInputChange}
                              type="password"
                              name="password"
                              className={
                                acceptField.password
                                  ? "form-control name_value border_success"
                                  : "form-control name_value"
                              }
                              placeholder="Your password"
                              value={loginData.password}
                            />
                            <div className="error_msg">{errors.password}</div>
                          </div>

                          <div className="login_button">
                            <Button type="submit" className="login_btn">
                              Log in
                            </Button>
                          </div>
                          <br />
                        </form>
                      </div>
                    </Collapse>

                    <div className="input_item other_link_area">
                      <div className="name">
                        <Link className="login_link float-left " to="/recover-password">
                          {" "}
                          Forgot Password ?
                        </Link>
                        <Link className="login_link float-right" to="/register">
                          Create an Account?
                        </Link>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
