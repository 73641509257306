import React, { useState, useEffect } from "react";

const BetHistoryDataView = (props) => {
  const [gameTransactions, setGameTransactions] = useState({});
  useEffect(() => {
    setGameTransactions(props.dataSet)
  }, [props]);

  return (
    <div className="transactions">

      <div className="transaction_row header_row bet_history">
        <div className="single_columns heading">Game</div>
        <div className="single_columns heading">Bet ID</div>
        <div className="single_columns heading">Date</div>
        <div className="single_columns heading">Amount</div>
        <div className="single_columns heading">Currency</div>
        <div className="single_columns heading"> Type</div>
        <div className="single_columns heading"> Multiplier</div>
      </div>

      {gameTransactions.length > 0 &&
        gameTransactions.map((game, index) => (
          <>
            <div className="transaction_row bet_history">
              <div className="single_columns">{game.game_name}</div>
              <div className="single_columns">
                {game.transaction_id}
              </div>
              <div className="single_columns">
                {game.record_date}
              </div>
              <div className="single_columns">{game.amount}</div>
              <div className="single_columns">{game.currency}</div>
              <div className="single_columns">{game.type}</div>
              <div className="single_columns">0.00X</div>
            </div>
          </>
        ))}
    </div>
  );
};

export default BetHistoryDataView;
