import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import "../styles/_pagination.scss";
const Pagination = (props) => {
  // const menuOpen = props.menuOpen;
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageActivate, setPageActivate] = useState(1);
  // const [pageNum, setPageNum] = useState(1);

  useEffect(() => {
    setPaginationData(props.paginationData);
    if(props.pageActivate){
      // setPage(props.pageActivate);
      setPageActivate(props.pageActivate);
    }
  }, [props]);

  useEffect(() => {
    props.pageNum(page);
    setPageActivate(page);
  }, [page]);

  return (
    <>
      <div className="pagination_content">
        {paginationData.length > 0 && (
          <>
            <ul className="pagination">
              <li class="">
                <a
                  class=""
                  onClick={() => {
                    if (page > 1) {
                      setPage(page - 1);
                    }
                  }}
                  href="javascript:"
                >
                  «
                </a>
              </li>
              {paginationData.map((val, index) => (
                <li class={val.page == pageActivate ? "active" : ""}>
                  <a
                    class=""
                    onClick={() => {
                      setPage(val.page);
                    }}
                    href="javascript:"
                  >
                    {val.page}
                  </a>
                </li>
              ))}
              <li class="">
                <a
                  class=""
                  onClick={() => {
                    setPage(page + 1);
                  }}
                  href="javascript:"
                >
                  »
                </a>
              </li>
            </ul>
          </>
        )}
      </div>
    </>
  );
};

export default Pagination;
