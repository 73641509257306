import React, { useState, useEffect } from "react";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import SuppotrSideBar from "elements/SuppotrSideBar";
import socket from "services/socket";
import ls from "services/ls";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import api from "services/api";
import swal from "sweetalert";
import Chat from "elements/Chat";
import {
  isMobile,
  isMobileByViewPort,
  cauculateLandScape,
} from "services/isMobile";
import ScrollTo from "elements/ScrollTo";
import Header from "elements/Header";
import Footer from "elements/Footer";

const FullLayout = ({ children, ...rest }) => {
  const { updateUserObj } = React.useContext(AppContext);
  const [openSupportSideBar, setOpenSupportSideBar] = useState();
  const showSupportSidebar = (receiveVal) => {
    setOpenSupportSideBar(receiveVal);
    setTimeout(function () {
      setOpenSupportSideBar(false);
    }, 500);
  };

  const [spinner, setSpinner] = useState(false);
  const [dummySpinner, setDummySpinner] = useState(false);
  const [supportSidebarOpen, setSupportSidebarOpen] = useState(false);

  const [chatInPopup, setChatInPopup] = useState(null);
  const location = useLocation();
  const [isLandscape, setIsLandscape] = useState(cauculateLandScape());
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isMobileDeviceByViewPort, setIsMobileDeviceByViewPort] =
    useState(false);
  const [isChatVisible, setIsChatVisible] = useState(
    isMobileDeviceByViewPort ? false : true
  );
  useEffect(() => {
    setIsChatVisible(!isMobileDeviceByViewPort);
  }, [isMobileDeviceByViewPort]);
  const showAlert = (title, message, isError, callback) => {
    swal({
      title: title,
      text: message,
      icon: isError ? "warning" : "success",
    }).then((value) => {
      if (callback) {
        callback(value);
      }
    });
  };

  const [isAuthenticated, setIsAuthenticated] = useState(
    auth.isAuthenticated()
  );
  const [userDetails, setUserDetails] = useState(auth.getUser);

  //Get user balance functionality
  const [userBalance, setUserBalance] = useState({
    cash_balance: 0,
    bonus_balance: 0,
    total_balance: 0,
    wallets: [],
    rates: [],
  });
  const getUserBalance = async (ev) => {
    var response = await api.get("/get-balance");
    console.log(response);
    if (response.status === 200) {
      ls.set("conversionRates", response.data.rates);
      setUserBalance(response.data);
    }
  };

  const [notificationCount, setNotifictionCount] = useState(0);
  const getNotificationsCount = async (ev) => {
    var response = await api.get("/player/notifications-count");
    if (response.status === 200) {
      setNotifictionCount(response.data);
    }
  };

  const setUsersWallet = async (currency) => {
    var response = await api.post("/set-wallet", { currency });
    console.log(response);
    if (response.status === 200) {
      setUserBalance(response.data);
      ls.set("conversionRates", response.data.rates);
    }
  };

  const [accountProgress, setAccountProgress] = useState(0);
  const getAccountProgress = async (ev) => {
    var response = await api.get("/account-progress");
    console.log("accountProgress", response);
    setAccountProgress(response.data);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsLandscape(cauculateLandScape());
    });
    setIsMobileDevice(isMobile());
    setIsMobileDeviceByViewPort(isMobileByViewPort());

    var interval;
    if (isAuthenticated) {
      getAccountProgress();
      getUserBalance();
      setUserDetails(auth.getUser());
      getNotificationsCount();
    }

    interval = setInterval(() => {
      if (isAuthenticated) {
        // if (!socket?.client?.connected) {
        //Not all balance altering processes report balance via socket yet
        //Only game transactiond do to this point
        getUserBalance();
        // }
        getNotificationsCount();
      }
    }, 15000);
    return () => {
      try {
        clearInterval(interval);
      } catch (ex) {}
    };
  }, []);

  const readBalanceFromSocket = (balance) => {
    if (isAuthenticated) {
      var balance = JSON.parse(balance);
      ls.set("conversionRates", balance.rates);
      setUserBalance(balance);
    }
  };

  useEffect(() => {
    socket.client.on("balance", readBalanceFromSocket);
    return () => {
      socket.client.off("balance");
    };
  }, [socket?.client?.connected]);

  const [themeMode, setThemeMode] = useState(ls.get("themeMode"));
  const passableParams = {
    showAlert,
    isMobileDevice,
    isMobileDeviceByViewPort,
    isLandscape,
    isAuthenticated,
    setIsAuthenticated,
    setSpinner,
    supportSidebarOpen,
    setSupportSidebarOpen,
    isChatVisible,
    setIsChatVisible,
    socket,
    themeMode,
    setThemeMode,
    userDetails,
    setUserDetails,
    userBalance,
    setUserBalance,
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, passableParams);
    }
    return child;
  });

  return (
    <>
      <div className={"home " + themeMode}>
        <div className="container">
          <Header {...passableParams} showSupportSidebar={showSupportSidebar} />

          <main>{childrenWithProps}</main>
          <Footer {...passableParams} showSupportSidebar={showSupportSidebar} />
          <SuppotrSideBar {...passableParams} />
        </div>
      </div>
      {isAuthenticated && (
        <Chat
          {...passableParams}
          chatInPopup={chatInPopup}
          setChatInPopup={setChatInPopup}
        />
      )}
      <ScrollTo />
    </>
  );
};

export default FullLayout;
