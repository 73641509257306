import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import moneyFormatter from "services/moneyFormatter";
import ls from "services/ls";
import { isMobile } from "services/isMobile";
import { __ } from "services/Translator"; 
// import DepositWithdrawalModal from "pages/players/parts/DepositWithdrawalModal";

import {
  Modal,
  Button,
  Alert,
  Dropdown,
  Form,
  Overlay,
  Tooltip,
  Accordion,
} from "react-bootstrap";

export default function Cashier(props) {
  // const __ = (text) => text;

  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  // const userBalance = props.userBalance;
  console.log("propspropsprops", props);
  const [selectedWallet, setSelectedWalelt] = useState(null);
  const [selectItems, setSelectItems] = useState([]);

  useEffect(() => {
    if (props.userBalance.wallets.length) {
      setSelectedWalelt(props.userBalance.wallets[0].currency);
      setSelectItems(
        props.userBalance.wallets.map((item) => {
          return { label: item.currency, value: item.value };
        })
      );
    }
  }, [props.userBalance]);

  const [userBalance, setUserBalance] = useState(0);

  const [callOpenDeposit, setCallOpenDeposit] = useState(false);
  const [callOpenWithdrawal, setCallOpenWithdrawal] = useState(false);
  // useEffect(() => {
  //   if (callOpenDeposit == true) {
  //     props.callOpenDeposit(callOpenDeposit);
  //   }
  // }, [callOpenDeposit]);

  // useEffect(() => {
  //   if (callOpenWithdrawal == true) {
  //     props.callOpenWithdrawal(callOpenWithdrawal);
  //   }
  // }, [callOpenWithdrawal]);

  //Get Balance Info

  const getUserBalance = async (ev) => {
    var response = await api.get("/get-balance");
    setUserBalance(response.data);
  };

  const [showBalanceAsFiat, setShowBalanceAsFiat] = useState(
    ls.get("showBalanceAsFiat") ? ls.get("showBalanceAsFiat") : false
  );

  const [fiatCurrencyToDisplay, setFiatCurrencyToDisplay] = useState(
    ls.get("fiatCurrencyToDisplay") ? ls.get("fiatCurrencyToDisplay") : "USD"
  );

  useEffect(() => {
    getUserBalance();
  }, []);
 //Opening wallet modal
 const [openWithdrawal, setOpenWithdrawal] = useState(false);
 const [openDeposit, setOpenDeposit] = useState(false);
 const [openCloseMyaccount, setOpenCloseMyaccount] = useState(false);
 // const [callOpenWithdrawal, setCallOpenWithdrawal] = useState(false);
 // const [callOpenDeposit, setCallOpenDeposit] = useState(false);

//  const callOpenWithdrawal = (receiveVal) => {
   
//    if (receiveVal) {
//      setOpenWithdrawal(receiveVal);     
//      setTimeout(function () {
//        setOpenWithdrawal(false);
//      }, 500);  
//    }
//  };

//  const callOpenDeposit = (receiveVal) => {
//    if (receiveVal) {
//      setOpenDeposit(receiveVal);   
//      setTimeout(function () {
//        setOpenDeposit(false);
//      }, 500);  
//    }
//  };
  return (
    <>
      <div className="my_account_area">
        <div className="my_account_content_area">
          <div className="wallet_area">
            <h3 className="tabName ">{__("Wallet")}</h3>
            <div className="wallet_content">
              <div className="balance_area">
                <div className="heading">
                  <div className="text">{__("Balance")}</div>
                  <div className="value">
                    {userBalance.wallets
                      ? showBalanceAsFiat
                        ? moneyFormatter.convertCurrencyToFiat(
                            userBalance.wallets[0].balance,
                            userBalance.wallets[0].currency,
                            fiatCurrencyToDisplay
                          )
                        : parseFloat(userBalance.wallets[0].balance).toFixed(8)
                      : ""}
                    <span className="deposit_icon">
                      {" "}
                      {userBalance.wallets &&
                        moneyFormatter.currencyIcon(
                          userBalance.wallets[0].currency
                        )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="available_to_withdraw_area">
                <div className="heading">
                  <div className="text">{__("Available to withdraw")}</div>
                  <div className="value">
                    {userBalance.wallets
                      ? showBalanceAsFiat
                        ? moneyFormatter.convertCurrencyToFiat(
                            userBalance.wallets[0].balance,
                            userBalance.wallets[0].currency,
                            fiatCurrencyToDisplay
                          )
                        : parseFloat(userBalance.wallets[0].balance).toFixed(8)
                      : ""}
                    <span className="deposit_icon">
                      {" "}
                      {userBalance.wallets &&
                        moneyFormatter.currencyIcon(
                          userBalance.wallets[0].currency
                        )}
                    </span>
                  </div>
                </div>
                <div className="info_text">
                  {__(
                    "Funds that are attached to an active bonus are not available to withdraw until the wagering requirements for that bonus have been met."
                  )}
                </div>
              </div>
              <div className="wallet_action_area">
                <Link
                  onClick={() => {
                    setCallOpenDeposit(true);
                    setTimeout(function () {
                      setCallOpenDeposit(false);
                    }, 500);
                  }}
                  className="common_btn colortwoBg"
                >
                  {__("Deposit")}
                </Link>
                <Link
                  onClick={() => {
                    setCallOpenWithdrawal(true);
                    setTimeout(function () {
                      setCallOpenWithdrawal(false);
                    }, 500);
                  }}
                  className="common_btn colortwoBg"
                >
                  {__("Withdraw")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <DepositWithdrawalModal openCloseMyaccount={openCloseMyaccount}  openWithdrawal={openWithdrawal} openDeposit={openDeposit} /> */}
    </>
  );
}
