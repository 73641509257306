import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "../services/auth";
import ls from "local-storage";
import AppContext from "../contexts/AppContext";
import api from "../services/api";
import emailValidate from "../services/emailValidate";

const Banner = (props) => {
  // const menuOpen = props.menuOpen;

  return (
    <>
      <section class="box_container">
        <div className="banner">
          <div class="container">
            <div class="row ">
              <div class="col-md-6 align-self-center">
                <div class="banner_text_section">
                  <div class="banner_title">
                    AMERICA'S #1
                    <br /> <span className="StyText">SOCIAL CASINO</span>
                    <br /> EXPERIENCE
                  </div>
                  <div class="banner_dsc">
                    Get ready for some serious fun and let the good times roll
                    across any device
                  </div>
                  <div class="banner_button_group">
                    <Link to="/register" className="common_button btnType-two ">
                      <span>Create account</span>
                      <svg width="13px" height="10px" viewBox="0 0 13 10">
                        <path d="M1,5 L11,5"></path>
                        <polyline points="8 1 12 5 8 9"></polyline>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="col-md-6 ">
                {/* <div class="banner_img animate-waves d-md-block d-none">
                  <img
                    src={
                      require("../assets/images/banners/landing-banner.png")
                        .default
                    }
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
