import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";

import api from "../services/api";
import auth from "../services/auth";
import AppContext from "../contexts/AppContext";
import emailValidate from "../services/emailValidate";
import ls from "local-storage";

import CategoryGames from "../elements/CategoryGames";
// import CategoryGames from "../components/ImageGalary";
import Banner from "../elements/Banner";
import ImageGalary from "../components/ImageGalary";
import SimpleSlider from "../pages/slider";
export default function Landing(props) {
  const [seconds, setSeconds] = useState(4083958);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 60);
    return () => clearInterval(interval);
  }, []);

  const [categoryName, setCategoryName] = useState("Slots");

  return (
    <>
      <Banner />

      <section className="box_container">
        <div className="online_games exciting_games">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="common_heading">
                  EXCITING ONLINE <br />
                  CASINO-STYLE GAMES
                </div>
                <div className="common_title">
                  With over 80 games to choose from, there is always
                  <br /> something new to play.
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="filter_game">
                  <ul>
                    <li>
                      <Link
                        className={
                          "common_button in_game_filter " +
                          (categoryName == "Slots" ? "active" : "")
                        }
                        onClick={() => {
                          setCategoryName("Slots");
                        }}
                      >
                        SLOTS
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={
                          "common_button in_game_filter " +
                          (categoryName == "Table Games" ? "active" : "")
                        }
                        onClick={() => {
                          setCategoryName("Table Games");
                        }}
                      >
                        TABLE GAMES
                      </Link>
                    </li>
                  </ul>

                  {/* <ul>
                    <li>
                      <Link
                        className="common_button in_game_filter active"
                        to="#"
                      >
                        {" "}
                        ALL GAMES
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="common_button in_game_filter"
                        to="/slots"
                      >
                        SLOTS{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="common_button in_game_filter"
                        to="/jackpot"
                      >
                        {" "}
                        JACKPOTS
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="common_button in_game_filter"
                        to="/table-games"
                      >
                        {" "}
                        TABLE GAMES
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>

            <div className="clearfix"></div>

            <CategoryGames categoryName={categoryName} perPage="40" title="" />
          </div>
        </div>
      </section>

      <section className="box_container">
        <div className="services service-one colorbar_top_left colorbar_bottom_right">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="service-box">
                  <div className="services_headline">
                    Over 1 Million Players
                  </div>
                  <div className="services_text">
                    With the chance to win prizes in exciting online
                    casino-style games, more and more customers are choosing
                     Casino each day.
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="service-box">
                  <div className="services_headline">Fastest Checkouts</div>
                  <div className="services_text">
                    We accept fast and secure payments from all the leading
                    payment providers without additional fees.
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="service-box">
                  <div className="services_headline">24/7 Customer Service</div>
                  <div className="services_text">
                    If you can't find the answer to your question you can always
                    get in touch with our Support team.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="box_container">
        <div className="fireshot colorbar ">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-12">
                <h3 className="common_heading fireshot_headline_title">
                  The Most Exciting Games!
                </h3>
                <div className="fireshot_desctiption">
                  The excitement increases with two slots games sharing <br />{" "}
                  the same progressive jackpot.
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-12 col-12">
                <div className="fireshot_game">
                  <SimpleSlider />
                </div>
                <div className="button_section">
                  <Link className="common_button btnType-two is-whitebg" to="#">
                    <span>Play now</span>
                    <svg width="13px" height="10px" viewBox="0 0 13 10">
                      <path d="M1,5 L11,5"></path>
                      <polyline points="8 1 12 5 8 9"></polyline>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="box_container">
        <div className="review colorbar colorbar_top_left colorbar_bottom_right">
          <div className="container">
            <div className="common_heading text-center">
              EXCITING ONLINE <br />
              CASINO-STYLE GAMES
            </div>
            <div className="common_title text-center">
              With over 80 games to choose from, there is always something new
              to play.
            </div>
            <div className="reviews">
              <div className="row">
                <div className="col-md-4">
                  <div className="review-shapeBox">
                    <div className="single_review">
                      <div className="review_content">
                        <span className="quote left-quote">“</span>Games are
                        awesome, redemptions are the quickest I have ever seen.
                      </div>
                      <div className="review_user">
                        <div className="username"> Larry H.</div>
                        <div className="porition">
                          Unsolicited testimonial from
                        </div>
                        <div className="porition">Indiana USA</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="review-shapeBox middle-boxRVW">
                    <div className="single_review">
                      <div className="review_content">
                        <span className="quote left-quote">“</span>I absolutely
                        love Moolah Bucks! I have won big and small, never had
                        issues with redeeming my wins. Their customer service is
                        A+ and has never left me with an unanswered question.
                      </div>
                      <div className="review_user">
                        <div className="username"> Angel Hall</div>
                        <div className="porition">
                          Unsolicited testimonial from
                        </div>
                        <div className="porition">USA</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="review-shapeBox">
                    <div className="single_review">
                      <div className="review_content">
                        <span className="quote left-quote">“</span>Thank you for
                        all the support through customer service. Very helpful
                        and patient with me.
                      </div>
                      <div className="review_user">
                        <div className="username"> Jason M.</div>
                        <div className="porition">
                          Unsolicited testimonial from
                        </div>
                        <div className="porition">Oklahoma USA</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="box_container">
        <div className="color_banner green_bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="banner_text_section">
                  <div className="common_heading">
                    NO PURCHASE <br />
                    NECESSARY
                  </div>
                  <div className="common_title on_banner">
                    It's always been free to play our Sweeps Coins games! We've
                    given away over 60 million free Sweeps Coins without any
                    purchases.
                  </div>
                  <div className="button_section">
                    <Link className="common_button btnType-two" to="#">
                      <span>find out more</span>
                      <svg width="13px" height="10px" viewBox="0 0 13 10">
                        <path d="M1,5 L11,5"></path>
                        <polyline points="8 1 12 5 8 9"></polyline>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="box_container">
        <div className="services service-two colorbar_top_left colorbar_bottom_right">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="service-box">
                  <div className="services_headline">
                    Exciting casino-style games
                  </div>
                  <div className="services_text">
                    We provide a selection of online casino-style games based on
                    Roulette, Blackjack, Video Poker, Table Games and Big
                    Jackpot slots.
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="service-box">
                  <div className="services_headline">No Downloads Required</div>
                  <div className="services_text">
                    Get straight to action through our easy to play web browser
                    games for your PC, Mac or mobile device.
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="service-box">
                  <div className="services_headline">
                    Exclusive First Time Offer
                  </div>
                  <div className="services_text">
                    Try before you commit by taking advantage of our exclusive
                    first time purchase offer.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="box_container calim_free">
        <div className="color_banner blue_bg colorbar ">
          <div className="container">
            <div className="row flex-md-row flex-column-reverse">
              <div className="col-md-6 noLeftPadding noRightPadding">
                <div className="banner_img_section">
                  <img
                    src={
                      require("../assets/images/banners/genie-moolah-bucks.png")
                        .default
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 align-self-center noRightPadding">
                <div className="banner_text_section">
                  <div className="common_heading text_end">
                    CLAIM YOUR FREE
                    <br /> SWEEPS COINS
                  </div>
                  <div className="common_title on_banner text_end">
                    Mail a request, win a competition or receive a free bonus
                    when you purchase Gold Coins.
                  </div>
                  <div className="button_section text_end">
                    <Link
                      className="common_button btnType-two is-whitebg"
                      to="#"
                    >
                      <span>find out more</span>
                      <svg width="13px" height="10px" viewBox="0 0 13 10">
                        <path d="M1,5 L11,5"></path>
                        <polyline points="8 1 12 5 8 9"></polyline>
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
