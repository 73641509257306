import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory } from "react-router-dom";
import auth from "../../services/auth";
import ls from "local-storage";
import AppContext from "../../contexts/AppContext";
import api from "../../services/api";

import { Button } from "react-bootstrap";

const SocialLogin = (props) => {
  // const menuOpen = props.menuOpen;
  //Auth State
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const { updateUserObj } = React.useContext(AppContext);

  const socialLoginAction = async (ev) => {
    var response = await api.post("/social-login", props.loginData);
    console.log(response.data);
    if (response.data.status == "success") {
      await auth.login(response.data.user);
      await updateUserObj(response.data.user);
      history.push("/");
    } else {
      // setErrors(response.data.errors);
    }
  };

  useEffect(() => {
    if (props.loginData) {
      socialLoginAction();
    }
  }, [props]);
  return <>Loading...</>;
};

export default SocialLogin;
