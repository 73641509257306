import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import emailValidate from "../../services/emailValidate";
import validator from "validator";
import swal from "sweetalert";
import api from "../../services/api";

import FacebookLogin from "react-facebook-login";
import AppleLogin from "react-apple-login";
import GoogleLogin from "react-google-login";

import SocialRegister from "./SocialRegister";
import SocialLogin from "./SocialLogin";

export default function Register() {
  const history = useHistory();

  const [errors, setErrors] = useState({});
  // const params = queryString.parse(window.location.search).p;

  const [acceptField, setAcceptField] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    rePassword: false,
  });

  const [registerData, setRegisterData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    rePassword: "",
    agreeToTerms: 0,
    acceptCountryLimitation: 0,
  });

  const registerValidateForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    // alert(44);
    if (registerData.firstName < 2 || registerData.firstName.length > 250) {
      errorsTmp = { ...errorsTmp, firstName: "Please enter first name" };
      valid = false;
    }
    if (registerData.lastName < 2 || registerData.lastName.length > 250) {
      errorsTmp = { ...errorsTmp, lastName: "Please enter last name" };
      valid = false;
    }
    if (registerData.email < 2 || registerData.email.length > 250) {
      errorsTmp = { ...errorsTmp, email: "Please enter your email" };
      valid = false;
    }
    if (!emailValidate(registerData.email)) {
      errorsTmp = { ...errorsTmp, email: "Your email not valid" };
      valid = false;
    }
    if (registerData.password < 2 || registerData.password.length > 250) {
      errorsTmp = { ...errorsTmp, password: "Please enter password" };
      valid = false;
    }

    if (!registerData.agreeToTerms) {
      errorsTmp = {
        ...errorsTmp,
        agreeToTerms: "Please read & accept terms & privacy policy",
      };
      valid = false;
    }
    if (!registerData.acceptCountryLimitation) {
      errorsTmp = {
        ...errorsTmp,
        acceptCountryLimitation: "Please read & accept country policy",
      };
      valid = false;
    }
    setErrors(errorsTmp);
    return valid;
  };

  const updateRegisterForm = (field, value) => {
    setRegisterData({ ...registerData, [field]: value });
  };

  const registerInputChange = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    if (field == "agreeToTerms") {
      var checked = ev.target.checked;
      if (checked) {
        value = 1;
      } else {
        value = 0;
      }
    }
    if (field == "acceptCountryLimitation") {
      var checked = ev.target.checked;
      if (checked) {
        value = 1;
      } else {
        value = 0;
      }
    }

    if (field == "firstName") {
      if (value.length > 2) {
        setAcceptField({ ...acceptField, firstName: true });
        setErrors({ ...errors, firstName: "" });
      } else {
        setAcceptField({ ...acceptField, firstName: false });
        setErrors({ ...errors, firstName: "Please enter first name" });
      }
    }
    if (field == "lastName") {
      if (value.length > 2) {
        setAcceptField({ ...acceptField, lastName: true });
        setErrors({ ...errors, lastName: "" });
      } else {
        setAcceptField({ ...acceptField, lastName: false });
        setErrors({ ...errors, lastName: "Please enter last name" });
      }
    }
    if (field == "email") {
      if (emailValidate(value)) {
        setAcceptField({ ...acceptField, email: true });
        setErrors({ ...errors, email: "" });

        var response = checkRegDuplicateEmail(registerData.email);
        var promise = Promise.resolve(response);
        promise.then(function (val) {
          if (val == true) {
            // setActiveStep(activeStep + 1);
          } else {
            // errorsTmp = {
            //   ...errorsTmp,
            //   email: val.email,
            // };

            // valid = false;
            // setRegErrors(errorsTmp);

            setAcceptField({ ...acceptField, email: false });
            setErrors({ ...errors, email: val.email });
          }

          console.log("PromisePromisePromise", val);
        });
      } else {
        setAcceptField({ ...acceptField, email: false });
        setErrors({ ...errors, email: "Your email not valid" });
      }
    }
    if (field == "password") {
      if (
        validator.isStrongPassword(value, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setAcceptField({ ...acceptField, password: true });
        setErrors({ ...errors, password: "" });
      } else {
        setAcceptField({ ...acceptField, password: false });
        setErrors({
          ...errors,
          password:
            "Password at least 8-30 characters,  one number, one special character, one lowercase and one upercase. Don't use email address or username.",
        });
      }
    }
    if (field == "rePassword") {
      var password = registerData.password;
      if (value === password) {
        setAcceptField({ ...acceptField, rePassword: true });
        setErrors({ ...errors, rePassword: "" });
      } else {
        setAcceptField({ ...acceptField, rePassword: false });
        setErrors({ ...errors, rePassword: "password doesn't match" });
      }
    }

    updateRegisterForm(field, value);
  };

  console.log("Data", registerData);

  const registerAction = () => {
    var valid = registerValidateForm();
    if (valid) {
      swal({
        title: "Congratulations!",
        text: "Your account just created, please check your email and verify your account.",
        icon: "success",
      });
      history.push("/login");
    }
  };

  const checkRegDuplicateEmail = async () => {
    // updateRegistrationForm(field, value);
    var response = await api.post("/check-reg-duplicate-email", {
      email: registerData.email,
    });
    if (response.status == 200) {
      return true;
    } else {
      return response.data.errors;
    }
  };

  const [spinner, setSpinner] = useState(false);

  //Register functionality START'S here

  const registrationAction = async (ev) => {
    ev.preventDefault();
    var valid = registerValidateForm();
    if (valid) {
      setSpinner(true);
      var response = await api.post("/light-signup", registerData);
      setSpinner(false);
      console.log(response.data);
      if (response.data.status == "success") {
        swal({
          title: "Congratulations!",
          text: "Your account just created, please check your email and verify your account.",
          icon: "success",
        });
        history.push("/login");

        // setShowRegModal(false);
        // history.push("/registration-complete");
      } else {
        setErrors(response.data.errors);
      }
    }
  };
  //Register functionality END'S here

  //social functionality
  const [showSocialRegTerms, setShowSocialRegTerms] = useState(false);
  const [socialRegisterData, setSocialRegisterData] = useState({
    name: "",
    email: "",
    socialId: "",
    provider: "",
    agreeToTerms: 0,
    acceptCountryLimitation: 0,
  });

  const responseFacebook = (response) => {
    console.log(response);
    setSocialRegisterData({
      name: response.name,
      email: response.email,
      socialId: response.userID,
      provider: "Facebook",
      agreeToTerms: 0,
      acceptCountryLimitation: 0,
    });
    // setSpinner(false);
    console.log("socialRegisterData", socialRegisterData);
  };

  const componentClicked = (response) => {
    console.log(response);
    setSpinner(true);
  };

  const successEesponseGoogle = (response) => {
    // console.log('responseresponseresponseresponse', response);
    setSocialRegisterData({
      name: response.profileObj.name,
      email: response.profileObj.email,
      socialId: response.profileObj.googleId,
      provider: "Google",
      agreeToTerms: 0,
      acceptCountryLimitation: 0,
    });
    // setSpinner(false);
  };

  const failureResponseGoogle = (response) => {
    console.log("failureResponseGoogle", response);
    setSpinner(false);
  };

  const [loginData, setLoginData] = useState({});
  const [applyLogin, setApplyLogin] = useState(false);
  const checkAvailability = async (ev) => {
    var response = await api.post("/check-social-availability", socialRegisterData);

    console.log(response.data);
    if (response.data.status == "Not Found") {
      setShowSocialRegTerms(true);
    } else if (response.data.status == "Already Registered") {
      // setShowSocialRegTerms(true);

      setLoginData({
        email: socialRegisterData.email,
        socialId: socialRegisterData.socialId,
        provider: socialRegisterData.provider,
      });
      setApplyLogin(true);
    } else if (response.data.status == "Email Already Registered") {
      setShowSocialRegTerms(true);
    } else {
      setSpinner(false);
    }
  };

  useEffect(() => {
    if (socialRegisterData.provider != "") {
      checkAvailability();
    }
  }, [socialRegisterData]);

  const [initialSettings, setInitialSettings] = useState({
    facebook_login_enabled: 0,
    google_login_enabled: 0,
    apple_login_enabled: 0,
  });
  const getInitialSettings = async (ev) => {
    var response = await api.get("/initial-settings");
    setInitialSettings(response.data);
  };

  useEffect(() => {
    getInitialSettings();
  }, []);

  return (
    <>
      <div className="box_container">
        {applyLogin && <SocialLogin loginData={loginData} />}

        {showSocialRegTerms && <SocialRegister socialRegisterData={socialRegisterData} />}

        {!showSocialRegTerms && (
          <div className="register">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-10 col-sm-10 col-md-8 col-lg-5">
                  <div className="form_area">
                    <div className="common_headline text-center">Sign up</div>

                    <form onSubmit={registrationAction}>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="input_item">
                            <div className="name reg_field">First Name</div>
                            <input
                              onChange={registerInputChange}
                              type="text"
                              name="firstName"
                              className={
                                acceptField.firstName
                                  ? "form-control name_value border_success"
                                  : "form-control name_value"
                              }
                              placeholder="First name"
                              value={registerData.firstName}
                            />
                            <div className="error_msg">{errors.firstName}</div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="input_item">
                            <div className="name reg_field">Last Name</div>
                            <input
                              onChange={registerInputChange}
                              type="text"
                              name="lastName"
                              className={
                                acceptField.lastName
                                  ? "form-control name_value border_success"
                                  : "form-control name_value"
                              }
                              placeholder="Last name"
                              value={registerData.lastName}
                            />
                            <div className="error_msg">{errors.lastName}</div>
                          </div>
                        </div>
                        <div className="col-12 col-md-12">
                          <div className="input_item">
                            <div className="name reg_field">Email</div>
                            <input
                              onChange={registerInputChange}
                              type="email"
                              name="email"
                              className={
                                acceptField.email ? "form-control name_value border_success" : "form-control name_value"
                              }
                              placeholder="Your email"
                              value={registerData.email}
                            />
                            <div className="error_msg">{errors.email}</div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="input_item">
                            <div className="name reg_field">Password</div>
                            <input
                              onChange={registerInputChange}
                              type="password"
                              name="password"
                              className={
                                acceptField.password
                                  ? "form-control name_value border_success"
                                  : "form-control name_value"
                              }
                              placeholder="Create a password"
                              value={registerData.password}
                            />
                            <div className="error_msg">{errors.password}</div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="input_item">
                            <div className="name reg_field">Confirm Password</div>
                            <input
                              onChange={registerInputChange}
                              type="password"
                              name="rePassword"
                              className={
                                acceptField.rePassword
                                  ? "form-control name_value border_success"
                                  : "form-control name_value"
                              }
                              placeholder="Confirm password"
                              value={registerData.rePassword}
                            />
                            <div className="error_msg">{errors.rePassword}</div>
                          </div>
                        </div>
                      </div>

                      <div className="input_item">
                        <div class="form-check">
                          <label for="register_tos-accept-checkbox" class="form-check-label">
                            <input
                              id="register_tos-accept-checkbox"
                              name="agreeToTerms"
                              class="form-check-input"
                              type="checkbox"
                              onChange={registerInputChange}
                              value={registerData.agreeToTerms}
                            />
                            I confirm the following: I am at least 18 years old; I agree to the{" "}
                            <Link target="_blank" rel="noopener noreferrer" to="/terms">
                              Terms of Use
                            </Link>{" "}
                            and{" "}
                            <Link target="_blank" rel="noopener noreferrer" to="/privacy">
                              Privacy Policy
                            </Link>{" "}
                            (including, for California residents, the{" "}
                            <Link target="_blank" rel="noopener noreferrer" to="#">
                              California Privacy Notice
                            </Link>
                            ); I do not have any existing Wild World Casinoaccounts. I understand Wild World Casinohas
                            the right to cancel all duplicate accounts and withold winnings made with such accounts.
                          </label>
                        </div>
                        <div className="error_msg">{errors.agreeToTerms}</div>
                      </div>
                      <div className="input_item">
                        <div class="form-check">
                          <label for="register_region-accept-checkbox" class="form-check-label">
                            <input
                              onChange={registerInputChange}
                              id="register_region-accept-checkbox"
                              name="acceptCountryLimitation"
                              class="form-check-input "
                              type="checkbox"
                              value={registerData.acceptCountryLimitation}
                            />
                            I understand that if I live outside United States or Canada, or within Washington State,
                            Idaho or Quebec, I cannot redeem my Sweeps Coins due to local laws.
                          </label>
                        </div>
                        <div className="error_msg">{errors.acceptCountryLimitation}</div>
                      </div>
                      <div className="login_button">
                        <Button type="submit" className="login_btn">
                          Create an Account
                        </Button>
                      </div>
                    </form>

                    <div class="or_text_section">
                      <div class="dash"></div>
                      <div class="paragraph white no_margin">OR SIGN UP WITH</div>
                      <div class="dash"></div>
                    </div>

                    <div className="login_button social_logins">
                      {initialSettings.facebook_login_enabled == true && (
                        <>
                          <br />
                          <FacebookLogin
                            appId={process.env.REACT_APP_FB_APP_ID || ""}
                            autoLoad={false}
                            fields="name,email,picture"
                            textButton=""
                            callback={responseFacebook}
                            onClick={componentClicked}
                            cssClass="login_btn facebook_btn"
                            icon="fab fa-facebook-square facebook_icon"
                          ></FacebookLogin>
                          &nbsp;
                          {/* <div className="input_item">
                          <div className="name or_text text-center">or</div>
                        </div> */}
                        </>
                      )}
                      {initialSettings.google_login_enabled == true && (
                        <>
                          <GoogleLogin
                            clientId={process.env.REACT_APP_GG_APP_ID || ""}
                            buttonText="Login"
                            onSuccess={successEesponseGoogle}
                            onFailure={failureResponseGoogle}
                            className="login_btn google_btn"
                            buttonText=""
                            cookiePolicy={"single_host_origin"}
                          />
                          &nbsp;
                        </>
                      )}
                    </div>

                    <div className="input_item pad_top">
                      <div className="name text-center">
                        <Link to="/login">Already have an account?</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
