import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "../services/auth";
import ls from "local-storage";
import AppContext from "../contexts/AppContext";
import api from "../services/api";
import emailValidate from "../services/emailValidate";
import moneyFormatter from "../services/moneyFormatter";
import { Modal, Button } from "react-bootstrap";

const BuyGoldCoins = (props) => {
  // buymodal
  const [buyModal, setBuyModal] = useState(false);

  const closeBuyModal = () => {
    setBuyModal(false);
  };

  //getBuyGoldCoinPlans functionality START'S here
  const [sweepstakeProductSD, setSweepstakeProductSD] = useState([]);
  const [sweepstakeProduct, setSweepstakeProduct] = useState([]);
  const getBuyGoldCoinPlans = async () => {
    var response = await api.get("/get-buy-gold-coin-plans");

    if (response.status === 200 && response.data) {
      setSweepstakeProductSD(response.data.sweepstakeProductSD);
      setSweepstakeProduct(response.data.sweepstakeProduct);
    }
  };

  useEffect(async () => {
    if (props.showBuyModal) {
      setBuyModal(props.showBuyModal);
      getBuyGoldCoinPlans();
    }
  }, [props.showBuyModal]);

  return (
    <>
      <Modal show={buyModal}>
        <Modal.Body className="buy_modal">
          <div className="buy_coins">
            <div className="buy_coin_header">
              <div className="page_title golden">Buy Gold Coins</div>
              <div onClick={closeBuyModal} className="modal_close">
                <i className="fal fa-times"></i>
              </div>
            </div>

            <div className="buy_coins_content">
              <div className="container">
                <div className="store__info">
                  <span className="store__not-needed">
                    It's always FREE to play our SWEEPS COINS games. No purchase
                    necessary to play.
                  </span>
                  <hr className="store__break"></hr>
                  <span className="store__giveaway">
                    Did you know?
                    <span className="store__giveaway--green">
                      &nbsp;We've given our players over 50 MILLION FREE Sweeps
                      Coins?
                    </span>
                  </span>
                </div>
                <div className="common_headline text-center">Offers</div>
                <br />

                <div className="offers">
                  {sweepstakeProductSD.length > 0 &&
                    sweepstakeProductSD.map((value, index) => (
                      <div className="offer">
                        <div className="offer_img">
                          <img
                            src={
                              require("../assets/images/icons/gold_popout.svg")
                                .default
                            }
                          />
                        </div>
                        <div className="offer_details">
                          <div className="offer_top">
                            <svg
                              viewBox="0 0 40 40"
                              fill="none"
                              className="package__gc-icon"
                            >
                              <path
                                d="M36.5834 19.9941C36.5834 30.6416 32.2152 39.3827 22.6142 39.9902C22.2593 40.0123 18.5095 39.9902 17.4163 39.9902C7.31232 39.9902 0 31.0362 0 19.9941C0 8.95188 7.73477 0.0445294 17.8366 0.0445294C18.7904 0.0445294 22.7819 0.0201439 23.1564 0.0445294C32.7312 0.680768 36.5834 9.36643 36.5834 19.9941Z"
                                fill="#E5A200"
                              ></path>
                              <path
                                d="M40.0004 19.9939C40.0004 31.0361 31.8105 39.99 21.7087 39.99C11.6069 39.99 3.41699 31.0361 3.41699 19.9939C3.41699 8.95168 11.6091 0 21.7109 0C31.8127 0 40.0004 8.95168 40.0004 19.9939Z"
                                fill="#FFC600"
                              ></path>
                              <path
                                d="M28.743 9.73743L28.5428 14.4016C28.5428 14.4016 20.5104 11.9195 19.7081 20.6062C19.7081 20.6062 18.786 26.7001 25.1864 25.8687L25.2601 23.8483L23.0895 24.498C23.0895 24.498 24.0224 21.3004 24.3167 20.131L29.3808 19.0506L29.1414 28.9432L24.9877 31.0566C24.9877 31.0566 24.9895 29.8474 24.9896 29.8452C23.9924 30.5302 21.9627 30.8933 20.9223 30.9617C18.3211 31.1327 16.0164 29.9834 14.7672 27.6014C12.5348 23.3466 13.3385 17.2163 15.8799 13.2082C17.2452 11.0553 19.5192 9.56921 21.9832 9.09028C22.7394 8.94249 25.9798 8.55412 28.743 9.73743Z"
                                fill="white"
                              ></path>
                            </svg>
                            <div className="package__gc-value">
                              <strong>GC</strong>{" "}
                              {moneyFormatter.numberFormat(value.gc)}
                            </div>
                            <div className="package__rrp">
                              ${value.previous_price}
                            </div>
                            <div className="package_btn">
                              <Link to="#">
                                Buy{" "}
                                {moneyFormatter.balanceFormat(
                                  value.price,
                                  "USD"
                                )}
                              </Link>
                            </div>
                          </div>

                          <div className="package__sweeps">
                            <span className="package__sweeps-text">
                              <svg
                                width="25"
                                height="23"
                                viewBox="0 0 25 23"
                                fill="none"
                                className="package__sweeps-icon"
                              >
                                <path
                                  d="M21.9554 11.337C21.9554 17.3743 19.3338 22.3306 13.5718 22.6751C13.3588 22.6876 11.1084 22.6751 10.4523 22.6751C4.38847 22.6751 0 17.5981 0 11.337C0 5.07586 4.642 0.0252493 10.7046 0.0252493C11.277 0.0252493 13.6725 0.0114224 13.8972 0.0252493C19.6435 0.386007 21.9554 5.31092 21.9554 11.337Z"
                                  fill="#3CA200"
                                ></path>
                                <path
                                  d="M24.0062 11.3369C24.0062 17.5979 19.091 22.675 13.0285 22.675C6.96591 22.675 2.05078 17.5979 2.05078 11.3369C2.05078 5.07575 6.96722 0 13.0298 0C19.0923 0 24.0062 5.07575 24.0062 11.3369Z"
                                  fill="#5EFF00"
                                ></path>
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M14.0245 2.29745L14.0329 2.29855C16.2919 2.5974 17.833 3.22089 19.5545 4.81564L19.805 5.04772L19.563 5.28936C19.1322 5.71964 18.7716 6.08003 18.3871 6.4644L18.1679 6.68354L17.9508 6.46206C16.0485 4.52049 13.3003 4.1459 10.9676 4.98579C8.63797 5.8246 6.76857 7.85712 6.58928 10.6876L6.58874 10.6962L6.58776 10.7047C6.21603 13.9404 7.94152 16.4105 10.3287 17.5333C12.7208 18.6583 15.7535 18.4175 17.9717 16.226L18.1931 16.0073L18.4088 16.232C18.5874 16.4179 18.7671 16.6392 18.9398 16.8519L18.9571 16.8732C19.1391 17.0973 19.3152 17.313 19.4933 17.498L19.7072 17.7203L19.4991 17.9483C14.8174 23.0781 5.49758 20.7098 4.32445 13.0269C3.37136 6.78758 8.07236 1.84452 14.0162 2.29681L14.0245 2.29745ZM13.3211 14.9942C12.041 15.049 10.8662 14.6628 9.75648 13.8816C9.66278 14.1018 9.53111 14.4537 9.39938 14.8058C9.26244 15.1718 9.12543 15.538 9.0309 15.7567C9.01545 15.7928 9.00089 15.8124 9.00281 15.8303C9.00733 15.8726 9.10419 15.9049 9.50021 16.1229C10.8367 16.858 12.2691 17.0972 13.7528 16.9836C15.6752 16.9449 17.7448 15.3972 17.3575 13.1445C17.1801 11.4123 15.5794 10.9473 14.2291 10.5549C14.1442 10.5302 14.0603 10.5059 13.9778 10.4815C13.7741 10.417 13.5693 10.3559 13.3646 10.2949C12.9223 10.1631 12.4802 10.0314 12.0498 9.86466C11.0848 9.49645 11.5974 8.02494 12.4533 7.7837C13.3368 7.53511 14.3907 7.76766 16.2698 8.54017C16.3704 8.27648 16.5058 7.92074 16.633 7.58663C16.7666 7.23574 16.8911 6.90871 16.9565 6.73721C14.1601 4.79524 9.03152 5.34589 9.20634 9.70294C9.27504 11.407 10.9253 11.8815 12.2686 12.2677C12.3587 12.2936 12.4474 12.3191 12.5341 12.3446C12.7264 12.4073 12.9194 12.4677 13.1124 12.5281C13.5831 12.6754 14.0537 12.8226 14.5122 13.0041C14.8544 13.1398 15.0862 13.4412 15.0618 13.8822C15.0214 14.7909 14.0435 14.9078 13.3726 14.988C13.3552 14.9901 13.3381 14.9922 13.3211 14.9942Z"
                                  fill="#3CA200"
                                ></path>
                              </svg>
                              free<strong> SC </strong>
                              {value.sc}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="common_headline text-center">store</div>
                <br />
                <div className="row">
                  <div className="col-md-8 offset-md-2">
                    <div className="stores">
                      {sweepstakeProduct.length > 0 &&
                        sweepstakeProduct.map((value, index) => (
                          <div className="single_store">
                            <div className="store_top">
                              <svg
                                viewBox="0 0 40 40"
                                fill="none"
                                className="package__gc-icon"
                              >
                                <path
                                  d="M36.5834 19.9941C36.5834 30.6416 32.2152 39.3827 22.6142 39.9902C22.2593 40.0123 18.5095 39.9902 17.4163 39.9902C7.31232 39.9902 0 31.0362 0 19.9941C0 8.95188 7.73477 0.0445294 17.8366 0.0445294C18.7904 0.0445294 22.7819 0.0201439 23.1564 0.0445294C32.7312 0.680768 36.5834 9.36643 36.5834 19.9941Z"
                                  fill="#E5A200"
                                ></path>
                                <path
                                  d="M40.0004 19.9939C40.0004 31.0361 31.8105 39.99 21.7087 39.99C11.6069 39.99 3.41699 31.0361 3.41699 19.9939C3.41699 8.95168 11.6091 0 21.7109 0C31.8127 0 40.0004 8.95168 40.0004 19.9939Z"
                                  fill="#FFC600"
                                ></path>
                                <path
                                  d="M28.743 9.73743L28.5428 14.4016C28.5428 14.4016 20.5104 11.9195 19.7081 20.6062C19.7081 20.6062 18.786 26.7001 25.1864 25.8687L25.2601 23.8483L23.0895 24.498C23.0895 24.498 24.0224 21.3004 24.3167 20.131L29.3808 19.0506L29.1414 28.9432L24.9877 31.0566C24.9877 31.0566 24.9895 29.8474 24.9896 29.8452C23.9924 30.5302 21.9627 30.8933 20.9223 30.9617C18.3211 31.1327 16.0164 29.9834 14.7672 27.6014C12.5348 23.3466 13.3385 17.2163 15.8799 13.2082C17.2452 11.0553 19.5192 9.56921 21.9832 9.09028C22.7394 8.94249 25.9798 8.55412 28.743 9.73743Z"
                                  fill="white"
                                ></path>
                              </svg>
                              <div className="package__gc-value">
                                <strong>GC</strong>{" "}
                                {moneyFormatter.numberFormat(value.gc)}
                              </div>

                              <div className="package_btn">
                                <Link to="#">
                                  Buy{" "}
                                  {moneyFormatter.balanceFormat(
                                    value.price,
                                    "USD"
                                  )}
                                </Link>
                              </div>
                            </div>

                            {value.sc > 0 && (
                              <div className="package__sweeps">
                                <span className="package__sweeps-text">
                                  <svg
                                    width="25"
                                    height="23"
                                    viewBox="0 0 25 23"
                                    fill="none"
                                    className="package__sweeps-icon"
                                  >
                                    <path
                                      d="M21.9554 11.337C21.9554 17.3743 19.3338 22.3306 13.5718 22.6751C13.3588 22.6876 11.1084 22.6751 10.4523 22.6751C4.38847 22.6751 0 17.5981 0 11.337C0 5.07586 4.642 0.0252493 10.7046 0.0252493C11.277 0.0252493 13.6725 0.0114224 13.8972 0.0252493C19.6435 0.386007 21.9554 5.31092 21.9554 11.337Z"
                                      fill="#3CA200"
                                    ></path>
                                    <path
                                      d="M24.0062 11.3369C24.0062 17.5979 19.091 22.675 13.0285 22.675C6.96591 22.675 2.05078 17.5979 2.05078 11.3369C2.05078 5.07575 6.96722 0 13.0298 0C19.0923 0 24.0062 5.07575 24.0062 11.3369Z"
                                      fill="#5EFF00"
                                    ></path>
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M14.0245 2.29745L14.0329 2.29855C16.2919 2.5974 17.833 3.22089 19.5545 4.81564L19.805 5.04772L19.563 5.28936C19.1322 5.71964 18.7716 6.08003 18.3871 6.4644L18.1679 6.68354L17.9508 6.46206C16.0485 4.52049 13.3003 4.1459 10.9676 4.98579C8.63797 5.8246 6.76857 7.85712 6.58928 10.6876L6.58874 10.6962L6.58776 10.7047C6.21603 13.9404 7.94152 16.4105 10.3287 17.5333C12.7208 18.6583 15.7535 18.4175 17.9717 16.226L18.1931 16.0073L18.4088 16.232C18.5874 16.4179 18.7671 16.6392 18.9398 16.8519L18.9571 16.8732C19.1391 17.0973 19.3152 17.313 19.4933 17.498L19.7072 17.7203L19.4991 17.9483C14.8174 23.0781 5.49758 20.7098 4.32445 13.0269C3.37136 6.78758 8.07236 1.84452 14.0162 2.29681L14.0245 2.29745ZM13.3211 14.9942C12.041 15.049 10.8662 14.6628 9.75648 13.8816C9.66278 14.1018 9.53111 14.4537 9.39938 14.8058C9.26244 15.1718 9.12543 15.538 9.0309 15.7567C9.01545 15.7928 9.00089 15.8124 9.00281 15.8303C9.00733 15.8726 9.10419 15.9049 9.50021 16.1229C10.8367 16.858 12.2691 17.0972 13.7528 16.9836C15.6752 16.9449 17.7448 15.3972 17.3575 13.1445C17.1801 11.4123 15.5794 10.9473 14.2291 10.5549C14.1442 10.5302 14.0603 10.5059 13.9778 10.4815C13.7741 10.417 13.5693 10.3559 13.3646 10.2949C12.9223 10.1631 12.4802 10.0314 12.0498 9.86466C11.0848 9.49645 11.5974 8.02494 12.4533 7.7837C13.3368 7.53511 14.3907 7.76766 16.2698 8.54017C16.3704 8.27648 16.5058 7.92074 16.633 7.58663C16.7666 7.23574 16.8911 6.90871 16.9565 6.73721C14.1601 4.79524 9.03152 5.34589 9.20634 9.70294C9.27504 11.407 10.9253 11.8815 12.2686 12.2677C12.3587 12.2936 12.4474 12.3191 12.5341 12.3446C12.7264 12.4073 12.9194 12.4677 13.1124 12.5281C13.5831 12.6754 14.0537 12.8226 14.5122 13.0041C14.8544 13.1398 15.0862 13.4412 15.0618 13.8822C15.0214 14.7909 14.0435 14.9078 13.3726 14.988C13.3552 14.9901 13.3381 14.9922 13.3211 14.9942Z"
                                      fill="#3CA200"
                                    ></path>
                                  </svg>
                                  free<strong> SC </strong>
                                  {value.sc}
                                </span>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BuyGoldCoins;
