import { set } from "local-storage";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import CategoryGames from "../elements/CategoryGames";
export default function Games(props) {
  const params = useParams();
  const [title, setTitle] = useState("");
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    if (params.gameType == "slots") {
      setCategoryName("Popular Slots");
      setTitle("Popular Slots");
    } else if (params.gameType == "jackpot") {
      setCategoryName("Popular Slots");
      setTitle("Jackpots");
    } else if (params.gameType == "table-games") {
      setCategoryName("Popular Slots");
      setTitle("Table Games");
    }

    // alert(params.gameType)
  }, [params]);

  const [selectedMenu, setSelectedMenu] = useState("games");

  // useEffect(() => {
  //   props.selectedMenu(selectedMenu);
  //   // alert(params.gameType)
  // }, [selectedMenu]);

  return (
    <div className="home ">
      <div className="box_container">
        <div className="home_banner slots_banner ">
          <div className="hero_details">
            <div className="delay fadeInUp">
              <img
                className="hero__logo"
                alt="hero banner logo"
                src={require("../assets/images/banners/slots_banner_left.png").default}
              />

              <p className="hero_description">Find your fortune and win the Dragon's Gold</p>
            </div>
            <div className="button_section">
              <Link className="common_button btnType-two is-imgbg" to="/games/slots">
                <span>Play Now</span>
                <svg width="13px" height="10px" viewBox="0 0 13 10">
                  <path d="M1,5 L11,5"></path>
                  <polyline points="8 1 12 5 8 9"></polyline>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container box_container">
        {/* <CategoryGames categoryName="Popular Slots" /> */}

        <CategoryGames categoryName={categoryName} perPage="40" title={title} />
      </div>
    </div>
  );
}
