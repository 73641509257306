import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";

import api from "../../services/api";
import auth from "../../services/auth";
import AppContext from "../../contexts/AppContext";
import emailValidate from "../../services/emailValidate";
import ls from "local-storage";
import { isMobile } from "../../services/isMobile";

import { Button } from "react-bootstrap";
import Pagination from "react-js-pagination";

import TransactionDataView from "./parts/TransactionDataView";
import TransactionMobileDataView from "./parts/TransactionMobileDataView";

export default function Transactions(props) {
  const [activePage, setActivePage] = useState(4);
  const [page, setPage] = useState(1);

  const [transactions, setTransactions] = useState({});
  const [transactionType, setTransactionType] = useState("Deposits");
  const [transactionPagination, setTransactionPagination] = useState({});
  const getTransactions = async (ev) => {
    var response = await api.post("/history/transactions", {
      page: page,
      historyFor: transactionType,
    });
    setTransactions(response.data.data);
    setTransactionPagination(response.data.paginationData);
    console.log("transactions", response.data);
  };
  useEffect(() => {
    getTransactions();
  }, [page, transactionType]);

  //GamePlay Page detection
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  useEffect(() => {
    setIsMobileDevice(isMobile());
  }, []);

  return (
    <>
      <div className="profile box_container">
        <div className="container">
          <div className="row">
            <div className={isMobileDevice ? "col-md-12" : "col-md-12"}>
              <div className="profile_right_content">
                <div className="profile_headline">
                  Transactions
                  <span className="toggle_content">
                    <div class="transaction_mode">
                      <Link
                        to="#"
                        onClick={() => setTransactionType("Deposits")}
                        className={
                          "mode_btn " +
                          (transactionType == "Deposits" ? "active" : "")
                        }
                      >
                        Deposits
                      </Link>
                      <Link
                        to="#"
                        onClick={() => setTransactionType("Withdrawals")}
                        className={
                          "mode_btn " +
                          (transactionType == "Withdrawals" ? "active" : "")
                        }
                      >
                        Withdrawals
                      </Link>
                    </div>
                  </span>
                </div>
                <div className="clearfix"></div>

                {!isMobileDevice && (
                  <TransactionDataView
                    transactionType={transactionType}
                    dataSet={transactions}
                  />
                )}

                {isMobileDevice && (
                  <TransactionMobileDataView
                    transactionType={transactionType}
                    dataSet={transactions}
                  />
                )}

                <div className="pagination_content">
                  {transactionPagination.length > 0 && (
                    <>
                      <ul className="pagination">
                        <li class="">
                          <a
                            class=""
                            onClick={() => {
                              if (page > 1) {
                                setPage(page - 1);
                              }
                            }}
                            href="javascript:"
                          >
                            «
                          </a>
                        </li>
                        {transactionPagination.map((val, index) => (
                          <li class={val.page == page ? "active" : ""}>
                            <a
                              class=""
                              onClick={() => {
                                setPage(val.page);
                              }}
                              href="javascript:"
                            >
                              {val.page}
                            </a>
                          </li>
                        ))}
                        <li class="">
                          <a
                            class=""
                            onClick={() => {
                              setPage(page + 1);
                            }}
                            href="javascript:"
                          >
                            »
                          </a>
                        </li>
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
