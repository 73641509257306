import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";

import api from "../services/api";
import auth from "../services/auth";
import AppContext from "../contexts/AppContext";
import emailValidate from "../services/emailValidate";
import ls from "local-storage";

import CategoryGames from "../elements/CategoryGames";
import Banner from "../elements/Banner";
export default function Privacy(props) {
  const [pageContent, setPageContent] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "privacy_policy",
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect(async () => {
    getContentPage();
  }, []);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <>
      <section className="  white box_container">
        <div className="CMS_content">
          <div className="page_title">
            {pageContent && pageContent.content_data.page_title}
          </div>
          <div className="common_paragraph">
            {pageContent && renderHTML(pageContent.content_data.content)}
          </div>
        </div>
      </section>
    </>
  );
}
