import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";

import api from "../services/api";
import auth from "../services/auth";
import AppContext from "../contexts/AppContext";
import emailValidate from "../services/emailValidate";
import ls from "local-storage";
import { Modal, Button, Alert } from "react-bootstrap";

import { isMobile, cauculateLandScape } from "../services/isMobile";

import CategoryGames from "../elements/CategoryGames";

export default function GamePlay(props) {
  const params = useParams();

  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully added",
    variant: "success",
    showAlert: false,
  });
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [launchUrl, setLaunchUrl] = useState("");
  const [gameDetails, setGameDetails] = useState("");
  const [gamePlayMode, setGamePlayMode] = useState(params.mode);
  const [gameSessionId, setGameSessionId] = useState("");
  const [fullScreenGame, setFullScreenGame] = useState(false);

  // const toggleFullScreen = () => {
  //   setFullScreenGame(!fullScreenGame);
  // };

  const [isPlayingTableGame, setIsPlayingTableGame] = useState(false);

  const isTableGame = (gameType) => {
    var tableGameTypes = [
      "video-bingo",
      "table-games",
      "video-poker",
      "poker",
      "livecasino",
      "live-casino-table",
      "live-casino",
    ];
    if (tableGameTypes.indexOf(gameType) !== -1) {
      return true;
    }

    return false;
  };

  const getPlay = async () => {
    var response = await api.post("/game/initsession", {
      slug: params.slug,
      mode: gamePlayMode,
    });

    if (response.status === 200 && response.data) {
      if (
        response.data.details &&
        response.data.details.type &&
        isTableGame(response.data.details.type)
      ) {
        setIsPlayingTableGame(true);
        if (gamePlayMode === "demo") {
          setAlertMsg({
            message: "Demo play is not avialable for live games",
            variant: "danger",
            showAlert: true,
          });
        } else {
          setLaunchUrl(response.data.launchUrl);
          setGameSessionId(response.data.sessionId);
        }
      } else {
        setIsPlayingTableGame(false);
        setLaunchUrl(response.data.launchUrl);
        setGameSessionId(response.data.sessionId);
      }
      setGameDetails(response.data.details);
    } else {
      setAlertMsg({
        message: response.data.message,
        variant: "danger",
        showAlert: true,
      });

      if (
        response.data &&
        response.data.details &&
        response.data.details.type &&
        isTableGame(response.data.details.type)
      ) {
        setIsPlayingTableGame(true);
      } else {
        setIsPlayingTableGame(false);
        setGamePlayMode("demo");
      }
    }
  };

  useEffect(async () => {
    getPlay();
  }, [gamePlayMode, params.slug]);

  useEffect(async () => {
    if (alertMsg.showAlert) {
      setTimeout(() => {
        setAlertMsg({
          message: "",
          variant: "success",
          showAlert: false,
        });
      }, 7000);
    }
  }, [alertMsg]);

  const changeGameMode = (mode) => {
    setGamePlayMode(mode);
    // return <Redirect to={"/play/"+params.slug+'/'+mode} />
  };

  //GameHub message functionality
  const [gameHubMessageHeading, setGameHubMessageHeading] = useState("");
  const [gameHubMessageDetails, setGameHubMessageDetails] = useState([]);
  const [gameHubMessageModal, setGameHubMessageModal] = useState(false);
  const [gameHubMessageShowed, setGameHubMessageShowed] = useState(false);
  const loadGameHubMessage = async () => {
    // console.log("gameSessionId", gameSessionId);
    if (
      isAuthenticated &&
      gamePlayMode == "real" &&
      gameSessionId &&
      gameHubMessageShowed != true
    ) {
      var response = await api.post("/load-game-hub_message", {
        game_session_id: gameSessionId,
        bet_type: "",
      });
      // console.log("loadGameHubMessage", response);
      if (response.status === 200 && response.data) {
        if (response.data.type == "found") {
          const msgRresponse = response.data.gamehub_message;
          setGameHubMessageModal(true);
          setGameHubMessageShowed(true);
          setGameHubMessageDetails(msgRresponse);
          if (msgRresponse.message_type == "maximum_cash_wagers_per_round") {
            setGameHubMessageHeading("Maximum cash wager limit exceeded!");
          } else if (
            msgRresponse.message_type == "maximum_bonus_wagers_per_round"
          ) {
            setGameHubMessageHeading("Maximum bonus wagers limit exceeded!");
          } else if (msgRresponse.message_type == "bonus_balance_not_support") {
            setGameHubMessageHeading("Insufficient balance!");
          } else if (msgRresponse.message_type == "maximum_wagers") {
            setGameHubMessageHeading("Maximum cash wager limit exceeded!");
          } else if (msgRresponse.message_type == "maximum_loss") {
            setGameHubMessageHeading("Maximum cash loss limit exceeded!");
          } else if (msgRresponse.message_type == "maximum_playing_time") {
            setGameHubMessageHeading("Maximum play time limit exceeded!");
          }
        }
      } else {
      }
    }
  };
  const closeAllModal = () => {
    setGameHubMessageModal(false);
    setGameHubMessageShowed(false);
  };
  useEffect(() => {
    var interval = setInterval(function () {
      loadGameHubMessage();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [gameSessionId, isAuthenticated, gamePlayMode, gameHubMessageShowed]);

  //GamePlay Page detection
  const isMobileDevice = isMobile();
  // const [isLandscape, setIsLandscape] = useState(cauculateLandScape());
  const [mobileIframeStyle, setMobileIframeStyle] = useState({});
  const calclateMobileIframeStyle = () => {
    var style = {
      width: "100vw",
      height: "calc(100vh - 52px)",
    };
    if (cauculateLandScape()) {
      style.height = "100vh";
    }
    setMobileIframeStyle(style);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      calclateMobileIframeStyle();
    });
    calclateMobileIframeStyle();
  }, []);

  //Full screen functionality

  const toggleFullScreen = () => {
    // setFullScreenGame(!fullScreenGame);

    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      var element = document.getElementById("gamesBody");
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  return (
    <>
      <>
        <div className="box_container">
          {!isMobileDevice && (
            <section className="game_play">
              <div
                className={
                  fullScreenGame
                    ? "game_play_content full_screen_content"
                    : "game_play_content"
                }
              >
                <div className="game_play_actions">
                  <div className="row">
                    <div className="col-5 col-sm-5 col-md-6">
                      <div className="game_name">{gameDetails.game_name}</div>
                      <div className="game_text">{gameDetails.category}</div>
                    </div>
                    <div className="col-7 col-sm-7 col-md-6 text-right">
                      <div className="action_right_block">
                        <div className="game_mode">
                          {/* {!isPlayingTableGame && (
                            <a
                              className={
                                "mood_btn " +
                                (gamePlayMode == "demo" ? "active" : "")
                              }
                              onClick={() => changeGameMode("demo")}
                              href="javascript:"
                            >
                              Fun Mode
                            </a>
                          )} */}
                          <a
                            className={
                              "mood_btn " +
                              (gamePlayMode == "real" ? "active" : "")
                            }
                            onClick={() => changeGameMode("real")}
                            href="javascript:"
                          >
                            Real Mode
                          </a>
                        </div>
                        <div className="fullscreen_btn">
                          <a onClick={toggleFullScreen}>
                            <img
                              src={
                                require("../assets/images/icons/full-screen.svg")
                                  .default
                              }
                            />
                          </a>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <Alert show={alertMsg.showAlert} variant={alertMsg.variant}>
                  {alertMsg.message}
                </Alert>
                <div className="games_body" id="gamesBody">
                  <iframe src={launchUrl} height="100%" width="100%" />
                </div>
              </div>
            </section>
          )}

          {isMobileDevice && (
            <>
              <Alert
                show={alertMsg.showAlert}
                className="gamplyMobileAlert"
                variant={alertMsg.variant}
              >
                {alertMsg.message}
              </Alert>
              <iframe src={launchUrl} style={mobileIframeStyle} />
            </>
          )}
        </div>

        <Modal show={gameHubMessageModal}>
          <Modal.Body className="login_modal forgot_pass">
            <div onClick={closeAllModal} className="modal_close">
              <i className="fal fa-times"></i>
            </div>
            <div className="modal_headline">{gameHubMessageHeading}</div>
            <div className="game_message_content">
              {gameHubMessageDetails.message_type ==
                "maximum_cash_wagers_per_round" && (
                <>
                  You have exceeded the maximum bet amount for cash money.
                  Please lower the amount and try again. See our{" "}
                  <Link to="">General Terms & Conditions</Link> for more
                  information.
                </>
              )}

              {gameHubMessageDetails.message_type ==
                "maximum_bonus_wagers_per_round" && (
                <>
                  You have exceeded the maximum bet amount for bonus money.
                  Please lower the amount and try again. See our{" "}
                  <Link to="">Bonus Terms</Link> for more information.
                </>
              )}

              {gameHubMessageDetails.message_type ==
                "bonus_balance_not_support" && (
                <>
                  You do not have sufficient balance to play this game. See our{" "}
                  <Link to="">Bonus Terms</Link> for more information. Note: For
                  Live Casino games and Sports Betting, Only cash wagers are
                  accepted (no bonus wagers
                </>
              )}

              {gameHubMessageDetails.message_type == "maximum_wagers" && (
                <>{gameHubMessageDetails.message}</>
              )}

              {gameHubMessageDetails.message_type == "maximum_loss" && (
                <>{gameHubMessageDetails.message}</>
              )}

              {gameHubMessageDetails.message_type == "maximum_playing_time" && (
                <>{gameHubMessageDetails.message}</>
              )}
            </div>
          </Modal.Body>
        </Modal>

        <br />
      </>

      {/* <CategoryGames categoryName="Popular Slots" />
      <CategoryGames categoryName="Top Sports" /> */}
      <br />
    </>
  );
}
