import ls from "local-storage";

//Auth module
import PrivateRoute from "./PrivateRoute";
import OpenRoute from "./OpenRoute";

// import external modules
import React, { Component, lazy } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

// Main Layout

import Landing from "../pages/Landing";
import GamePlay from "../pages/GamePlay";
import Terms from "../pages/Terms";
import Privacy from "../pages/Privacy";
import ResponsibleGaming from "../pages/ResponsibleGaming";
import Profile from "../pages/Profile";
import About from "../pages/About";
import Contact from "../pages/Contact";
import ContactDetails from "../pages/ContactDetails";
import Home from "../pages/Home";
import Slots from "../pages/Slots";
import Jackpot from "../pages/Jackpot";
import TableGames from "../pages/TableGames";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import RecoverPassword from "../pages/auth/RecoverPassword";
import ResetPassword from "../pages/auth/ResetPassword";

import Games from "../pages/Games";
import GameplayHistory from "../pages/GameplayHistory";
import BetHistory from "../pages/BetHistory";
import Transactions from "pages/players/Transactions";

import UserDashboard from "pages/UserDashboard";

// const Landing = lazy(() => import("../pages/Landing"));
// import { BrowserRouter, Switch, Redirect } from "react-router-dom";
class OpenRoutes extends Component {
  // const history = useHistory()
  //   useEffect(() => {
  //     const unlisten = history.listen((location, action) => {
  //       if (action !== 'POP') {
  //         window.scrollTo(0, 0)
  //       {
  //     })
  //     return () => unlisten()
  //   }, [])

  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          {/* Auth pages route */}
          <OpenRoute exact path="/" component={Landing}></OpenRoute>
          <OpenRoute
            exact
            path="/play/:slug/:mode"
            component={GamePlay}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/terms-and-conditions"
            component={Terms}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/privacy-policy"
            component={Privacy}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/responsible-social-gameplay-policy"
            component={ResponsibleGaming}
          ></OpenRoute>

          <OpenRoute
            exact
            path="/bet-history"
            component={BetHistory}
          ></OpenRoute>
          <OpenRoute exact path="/about-us" component={About}></OpenRoute>
          <OpenRoute exact path="/contact-us" component={Contact}></OpenRoute>
          <OpenRoute
            exact
            path="/contact-details"
            component={ContactDetails}
          ></OpenRoute>

          <OpenRoute exact path="/login" component={Login}></OpenRoute>
          <OpenRoute exact path="/register" component={Register}></OpenRoute>
          <OpenRoute
            exact
            path="/recover-password"
            component={RecoverPassword}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/reset-password/:userId/:code"
            component={ResetPassword}
          ></OpenRoute>

          <OpenRoute exact path="/home" component={Home}></OpenRoute>

          <OpenRoute
            exact
            path="/games/:gameType"
            component={Games}
          ></OpenRoute>
          {/* <OpenRoute exact path="/games/:slots" component={Games}></OpenRoute>
          <OpenRoute exact path="/games/:jackpot" component={Jackpot}></OpenRoute>
          <OpenRoute
            exact
            path="/games/:table-games"
            component={TableGames}
          ></OpenRoute> */}

          <OpenRoute exact path="/profile" component={Profile}></OpenRoute>
          {
            // <OpenRoute exact path="/terms" component={Terms}></OpenRoute>
            // <OpenRoute exact path="/privacy" component={Privacy}></OpenRoute>
            // <OpenRoute exact path="/responsible-gaming" component={ResponsibleGaming}></OpenRoute>
          }

          <Redirect path="*" to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

class PrivateRoutes extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          {/*Employee routing*/}
          <PrivateRoute exact path="/" component={Landing}></PrivateRoute>
          <PrivateRoute
            exact
            path="/dashboard/:tab?"
            component={UserDashboard}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/play/:slug/:mode"
            component={GamePlay}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/terms-and-conditions"
            component={Terms}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/privacy-policy"
            component={Privacy}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/responsible-social-gameplay-policy"
            component={ResponsibleGaming}
          ></PrivateRoute>
          <PrivateRoute exact path="/about-us" component={About}></PrivateRoute>
          <PrivateRoute
            exact
            path="/contact-us"
            component={Contact}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/contact-details"
            component={ContactDetails}
          ></PrivateRoute>

          <PrivateRoute exact path="/home" component={Home}></PrivateRoute>

          <PrivateRoute
            exact
            path="/games/:gameType"
            component={Games}
          ></PrivateRoute>
          {/* <PrivateRoute
            exact
            path="/games/:gameType"
            component={Jackpot}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/games/:gameType"
            component={TableGames}
          ></PrivateRoute> */}

          <PrivateRoute
            exact
            path="/profile"
            component={Profile}
          ></PrivateRoute>
          {/* <PrivateRoute
            exact
            path="/bet-history"
            component={BetHistory}
          ></PrivateRoute> */}
          <PrivateRoute
            exact
            path="/gameplay-history"
            component={BetHistory}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/history"
            component={Transactions}
          ></PrivateRoute>

          <Redirect path="*" to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

export { OpenRoutes, PrivateRoutes };
