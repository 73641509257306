import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";

import api from "../services/api";

export default function Landing(props) {

  const [pageContent, setPageContent] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "responsible_gaming",
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect(async () => {
    getContentPage();
  }, []);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  return (
    <>
      <section className=" box_container ">
        <div class="container CMS_content white">
          <div className="page_title">            
            {pageContent && pageContent.content_data.page_title}
          </div>
          <div className="common_paragraph">
            {pageContent && renderHTML(pageContent.content_data.content)}
          </div>
        </div>
      </section>
      
    </>
  );
}
