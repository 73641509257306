import React, { useState, useEffect, useRef } from "react";
// import { liveChatUrl } from "../configs/config.js";
import config from "../../../configs/config.js";
import api from "../../../services/api";
import {
  Modal,
  Alert,
  Accordion,
  Button,
  Dropdown,
  Form,
  Overlay,
  Tooltip,
} from "react-bootstrap";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import auth from "../../../services/auth";
import ls from "local-storage";
import AppContext from "../../../contexts/AppContext";
import moneyFormatter from "../../../services/moneyFormatter";

import "../../../styles/_header_wallet.scss";

const HeaderWalletBalance = (props) => {
  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully added",
    variant: "success",
    showAlert: false,
  });

  //GamePlay Page detection
  const location = useLocation();

  const [showInPlay, setShowInPlay] = useState(false);
  const playPath = location.pathname.split("/");

  useEffect(() => {
    setShowInPlay(playPath.includes("play"));
  }, [playPath]);

  //Get user balance functionality

  const { updateUserObj } = React.useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [userDetails, setUserDetails] = useState(auth.getUser);

  const [userBalance, setUserBalance] = useState({});
  const getUserBalance = async (ev) => {
    var response = await api.get("/get-balance");
    console.log(response);
    ls.set("conversionRates", response.data.rates);
    setUserBalance(response.data);
  };

  const [accountProgress, setAccountProgress] = useState({});
  const getAccountProgress = async (ev) => {
    var response = await api.get("/account-progress");
    console.log("accountProgress", response);
    setAccountProgress(response.data);
  };

  const setUsersWallet = async (currency) => {
    var response = await api.post("/set-wallet", { currency });
    console.log(response);
    if (response.status === 200) {
      setUserBalance(response.data);
      ls.set("conversionRates", response.data.rates);
    }
  };

  useEffect(() => {
    var interval;
    if (isAuthenticated) {
      getAccountProgress();
      getUserBalance();
      setUserDetails(auth.getUser);
      // moneyFormatter.getConversionRates();
    }

    interval = setInterval(() => {
      if (isAuthenticated) {
        getUserBalance();
        // moneyFormatter.getConversionRates();
      }
    }, 30000);
    return () => {
      try {
        clearInterval(interval);
      } catch (ex) {}
    };
  }, []);

  // walletModal
  const [depositWallets, setDepositWallets] = useState([]);
  const [selectedDepositWallet, setSelectedDepositWallet] = useState("BTC");

  const [selectedWithdrwalWallet, setSelectedWithdrwalWallet] = useState("BTC");
  const [withdrwalWalletAddress, setWithdrwalWalletAddress] = useState("");
  const [withdrwalAmount, setWithdrwalAmount] = useState("");

  const [showWalletModal, setShowWalletModal] = useState(false);
  const openWalletModal = async () => {
    if (userBalance.wallets.length) {
      setShowWalletModal(true);

      setWithdrwalMessage(null);
      setWithdrwalMessageType("success");

      setSelectedDepositWallet(userBalance.wallets[0].currency);
      setSelectedWithdrwalWallet(userBalance.wallets[0].currency);
      var response = await api.get("/deposit/wallets");
      if (response.status === 200) {
        setDepositWallets(response.data);
      }
    }
  };

  const closeWalletModal = () => {
    setShowWalletModal(false);
  };

  const [showBalanceAsFiat, setShowBalanceAsFiat] = useState(
    ls.get("showBalanceAsFiat") ? ls.get("showBalanceAsFiat") : false
  );
  const [hideEmptyWallets, setHideEmptyWallets] = useState(
    ls.get("hideEmptyWallets") ? ls.get("hideEmptyWallets") : false
  );
  const [fiatCurrencyToDisplay, setFiatCurrencyToDisplay] = useState(
    ls.get("fiatCurrencyToDisplay") ? ls.get("fiatCurrencyToDisplay") : "USD"
  );
  const [showFiatChooserModal, setShowFiatChooserModal] = useState(false);
  const toggleShowBalanceAsFiat = () => {
    if (!showBalanceAsFiat) {
      setShowFiatChooserModal(true);
    }
    ls.set("showBalanceAsFiat", !showBalanceAsFiat);
    setShowBalanceAsFiat(!showBalanceAsFiat);
  };
  const toggleHideEmptyWallets = () => {
    ls.set("hideEmptyWallets", !hideEmptyWallets);
    setHideEmptyWallets(!hideEmptyWallets);
  };
  const fiatToDisplaySelected = (ev) => {
    ls.set("fiatCurrencyToDisplay", ev.target.value);
    setFiatCurrencyToDisplay(ev.target.value);
  };
  const closeFiatChooserModal = () => {
    setShowFiatChooserModal(false);
  };

  const [withdrwalMessage, setWithdrwalMessage] = useState(null);
  const [withdrwalMessageType, setWithdrwalMessageType] = useState("success");

  const withdraw = async () => {
    if (!withdrwalWalletAddress) {
      setWithdrwalMessage(
        "Please enter the " +
          selectedWithdrwalWallet +
          " address for withdrawal"
      );
      setWithdrwalMessageType("danger");
      return false;
    }
    var amount = parseFloat(withdrwalAmount);
    if (!amount || isNaN(amount)) {
      setWithdrwalMessage("Please enter a valid amount for withdrawal");
      setWithdrwalMessageType("danger");
      return false;
    }
    var postData = {
      amount: amount,
      method: "COINSPAID:" + selectedWithdrwalWallet,
      accNumber: withdrwalWalletAddress,
    };
    var response = await api.post("/withdraw", postData);
    if (response.status === 200) {
      setWithdrwalAmount("");
      setWithdrwalWalletAddress("");
      setWithdrwalMessage("Your withdrwal request has been received");
      setWithdrwalMessageType("success");

      setTimeout(() => {
        setWithdrwalMessage(null);
      }, 3000);
    } else {
      alert(response.data.message);
    }
  };

  const [show, setShow] = useState(false);

  const wageringTooltipRef = useRef(null);
  const [wageringTooltipShow, setWageringTooltipShow] = useState(false);
  useEffect(() => {
    var timeout;
    if (wageringTooltipShow) {
      timeout = setTimeout(() => {
        setWageringTooltipShow(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [wageringTooltipShow]);

  const [callOpenDeposit, setCallOpenDeposit] = useState(false);
  const [callOpenWithdrawal, setCallOpenWithdrawal] = useState(false);
  // wallet tabs
  const [activeWalletTab, setActiveWalletTab] = useState("deposit");
  const toggleWalletTab = (tabName) => {
    setActiveWalletTab(tabName);
  };

  const target = useRef(null);

  useEffect(() => {
    var timeout;
    if (wageringTooltipShow) {
      timeout = setTimeout(() => {
        setWageringTooltipShow(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [wageringTooltipShow]);

  const copyDepositAddress = () => {
    var copyText = document.getElementById("deposit_address");
    // var copyText = document.getElementById("myInput");

    /* Select the text field */
    // copyText.select();
    // copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    setShow(true);
    const timer = setTimeout(() => {
      setShow(false);
    }, 2000);
    return () => clearTimeout(timer);
  };

  // useEffect(() => {
  //   if (callOpenDeposit == true) {
  //     props.callOpenDeposit(callOpenDeposit);
  //   }
  // }, [callOpenDeposit]);

  return (
    <>
      <div className="wallet_menus">
        <ul>
          {isAuthenticated && (
            <li className="wallet_menu_area">
              <div className="deposit_block">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    className="right profile_dropdown wallets_dropdown"
                    id="dropdown-basic1"
                  >
                    {!showInPlay && (
                      <div className="deposit_amount">
                        {userBalance.wallets
                          ? showBalanceAsFiat
                            ? moneyFormatter.convertCurrencyToFiat(
                                userBalance.wallets[0].balance,
                                userBalance.wallets[0].currency,
                                fiatCurrencyToDisplay
                              )
                            : parseFloat(
                                userBalance.wallets[0].balance
                              ).toFixed(8)
                          : ""}
                      </div>
                    )}

                    {showInPlay && (
                      <div
                        onClick={() => getUserBalance()}
                        className="deposit_amount"
                      >
                        (In Play)
                      </div>
                    )}

                    <div className="deposit_icon">
                      {" "}
                      {userBalance.wallets &&
                        moneyFormatter.currencyIcon(
                          userBalance.wallets[0].currency
                        )}
                    </div>
                    <div className="wallet_caret">
                      <i className="fal downarrow fa-caret-down"></i>
                      <i className="fal uparrow fa-caret-up"></i>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="wallets_menu">
                    <div className="modal_top_arrow"></div>
                    <div>
                      <div className="wallets_head row">
                        <div className="col-6"></div>
                        <div className="col-6 text-right">Crypto</div>
                      </div>

                      {userBalance.wallets &&
                        userBalance.wallets.map((item, index) => {
                          if (!item.balance && hideEmptyWallets) {
                            return null;
                          }
                          return (
                            <Dropdown.Item as="button" key={index}>
                              <div
                                className="wallets_balance row"
                                key={index}
                                onClick={() => setUsersWallet(item.currency)}
                              >
                                <div className="col-6">
                                  {showBalanceAsFiat
                                    ? moneyFormatter.convertCurrencyToFiat(
                                        item.balance,
                                        item.currency,
                                        fiatCurrencyToDisplay
                                      )
                                    : parseFloat(item.balance).toFixed(8)}
                                </div>
                                <div className="col-6 ">
                                  {moneyFormatter.currencyWithIcon(
                                    item.currency
                                  )}
                                </div>
                              </div>
                            </Dropdown.Item>
                          );
                        })}

                      {userBalance.bonus_balance && (
                        <Dropdown.Item as="button">
                          <div className="wallets_balance row">
                            <div className="col-6">
                              {userBalance.currency +
                                "" +
                                parseFloat(userBalance.bonus_balance).toFixed(
                                  2
                                )}
                            </div>

                            <div
                              ref={wageringTooltipRef}
                              onMouseOver={() => setWageringTooltipShow(true)}
                              onMouseOut={() => setWageringTooltipShow(false)}
                              className="col-6 text-right"
                            >
                              BONUS
                              <br />
                              <div className="progress_bar">
                                <div className="progress">
                                  <div
                                    style={{
                                      width:
                                        userBalance.wagering_percentage + "%",
                                    }}
                                    className="progress-bar"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Item>
                      )}

                      <div className="wallets_bottom row">
                        <div className="wallets_switch">
                          <div className="col-11 offset-1">
                            <Form.Check
                              checked={showBalanceAsFiat}
                              type="switch"
                              onChange={toggleShowBalanceAsFiat}
                              label="Show as fiat"
                              id="show_as_fiat"
                            />
                          </div>
                        </div>

                        <div className="wallets_switch">
                          <div className="col-11 offset-1">
                            <Form.Check
                              checked={hideEmptyWallets}
                              type="switch"
                              label="Hide empty wallets"
                              id="hide_empty_wallets"
                              onChange={toggleHideEmptyWallets}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                <div className="deposit_button">
                  <Link
                    onClick={() => {
                      // setCallOpenDeposit(true);
                      // setTimeout(function () {
                      //   setCallOpenDeposit(false);
                      // }, 500);
                      setShowWalletModal(true);
                    }}
                    className="deposit_btn"
                  >
                    Wallet
                  </Link>
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>

      {/* <Modal show={showFiatChooserModal} onHide={closeFiatChooserModal}> */}

      <Modal show={showFiatChooserModal}>
        <Modal.Body className="buy_modal">
          <div class="buy_coins">
            <div class="buy_coin_header">
              <Link
                to="#"
                className="close_button on_reg"
                onClick={() => {
                  setShowFiatChooserModal(false);
                }}
              >
                <svg
                  width="19"
                  height="19"
                  class="modal_close"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <title>close</title>
                  <path d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z"></path>
                </svg>
              </Link>
              <h4 class="page_title golden text-center">Information</h4>
              <br />
            </div>
            <div className={"buy_coins_content fiat_selectors_modal"}>
              {/* <div className="wallet_headline"></div> */}
              <div className="container">
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <div className="privacy_text">
                      <br />
                      <br />
                      Please note that these are currency approximations.
                      <br />
                      <br />
                      All bets & transactions will be settled in the crypto
                      equivalent. For further details feel free to contact our
                      live support.
                    </div>

                    <div className="form_area">
                      <div className="form_row fiat_selectors">
                        {["USD", "EUR"].map((currency, index) => (
                          <div className="fiat_select" key={index}>
                            <input
                              type="radio"
                              value={currency}
                              checked={fiatCurrencyToDisplay === currency}
                              id={"fiat_selector" + currency}
                              onChange={fiatToDisplaySelected}
                            />
                            <label
                              for={"fiat_selector" + currency}
                              className="radio_area"
                            >
                              <div className="radio_check_and_text_area">
                                <div className="radio_box">
                                  <div className="radio_checked_box"></div>
                                </div>
                                <div className="radio_text">
                                  {moneyFormatter.currencyIcon(currency)}{" "}
                                  <span className="currency">{currency}</span>
                                </div>
                              </div>
                            </label>
                          </div>
                        ))}
                      </div>

                      <div className="login_button">
                        <Link
                          onClick={closeFiatChooserModal}
                          className="common_button"
                        >
                          Play Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showWalletModal} onHide={closeWalletModal}>
        <Modal.Body className="buy_modal">
          <div class="buy_coins">
            <div class="buy_coin_header">
              <div onClick={closeWalletModal} className="modal_close">
                <i className="fal fa-times"></i>
              </div>
              <div className="page_title golden text-center">Wallet</div>
            </div>
            <div className={"buy_coins_content fiat_selectors_modal"}>
              <br/>
              <br/>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <div className="login_mode in_deposit_modal">
                      <Link
                        to="#"
                        onClick={() => toggleWalletTab("deposit")}
                        className={
                          activeWalletTab === "deposit"
                            ? "mode_btn common_button active"
                            : "mode_btn common_button"
                        }
                      >
                        Deposit
                      </Link>
                      <Link
                        to="#"
                        onClick={() => toggleWalletTab("withdraw")}
                        className={
                          activeWalletTab === "withdraw"
                            ? "mode_btn common_button active"
                            : "mode_btn common_button"
                        }
                      >
                        Withdraw
                      </Link>
                    </div>

                    {activeWalletTab === "deposit" && (
                      <>
                        <div className="form_area">
                          <div className="input_item">
                            <div className="name">Cryptocurrency</div>

                            <Dropdown className="currency_select_menu">
                              <Dropdown.Toggle
                                variant="success"
                                className="right select_crypto"
                                id="dropdown-basic1"
                              >
                                {moneyFormatter.currencyFullWithIcon(
                                  selectedDepositWallet
                                )}
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="wallets_menu">
                                {!!userBalance.wallets &&
                                  userBalance.wallets.map((item, index) => {
                                    return (
                                      <Dropdown.Item as="button" key={index}>
                                        <div
                                          className="wallets_balance"
                                          onClick={() =>
                                            setSelectedDepositWallet(
                                              item.currency
                                            )
                                          }
                                        >
                                          {moneyFormatter.currencyFullWithIcon(
                                            item.currency
                                          )}
                                        </div>
                                      </Dropdown.Item>
                                    );
                                  })}
                              </Dropdown.Menu>
                            </Dropdown>
                            <div className="clearfix"></div>
                          </div>

                          <div className="privacy_text bordered_text">
                            We are currently unable to process{" "}
                            {moneyFormatter.currencyName(selectedDepositWallet)}{" "}
                            deposits that are less than $50.00{" "}
                            {moneyFormatter.currencyIcon(selectedDepositWallet)}
                            <br />
                            Only send {selectedDepositWallet} to this address, 1
                            confirmation requried. We do not accept BEP20 from
                            Binance.
                          </div>

                          <div className="input_item walletAddress">
                            <div className="name">
                              {selectedDepositWallet} Address
                              <span className="requried_icon">*</span>
                            </div>
                            <input
                              type="text"
                              name="btc"
                              id="deposit_address"
                              value={
                                depositWallets[selectedDepositWallet]
                                  ? depositWallets[selectedDepositWallet]
                                      .address
                                  : ""
                              }
                              className="form-control name_value"
                            />
                            -
                            <a
                              className="in_input_icon"
                              onClick={copyDepositAddress}
                              href="javascript:"
                              ref={target}
                              // onClick={() => setShow(!show)}
                            >
                              <img
                                alt=""
                                src={
                                  require("../../../assets/images/icons/copy-icon.svg")
                                    .default
                                }
                              />
                            </a>
                            <Overlay
                              target={target.current}
                              show={show}
                              placement="top"
                            >
                              {(props) => (
                                <Tooltip
                                  id="overlay-example"
                                  className="tooltip"
                                  {...props}
                                >
                                  Copied
                                </Tooltip>
                              )}
                            </Overlay>
                          </div>
                          <div className="input_item">
                            <div className="qr_code in_wallet_modal">
                              <img
                                className="wallet_qr"
                                src={
                                  config.apiUrl +
                                  "/qr-code/" +
                                  (depositWallets[selectedDepositWallet]
                                    ? depositWallets[selectedDepositWallet]
                                        .qr_text
                                    : "")
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {activeWalletTab === "withdraw" && (
                      <>
                        <div className="form_area">
                          <div className="input_item">
                            <div className="name">Cryptocurrency</div>

                            {withdrwalMessage && (
                              <Alert variant={withdrwalMessageType}>
                                {withdrwalMessage}
                              </Alert>
                            )}

                            <Dropdown className="currency_select_menu">
                              <Dropdown.Toggle
                                variant="success"
                                className="right select_crypto"
                                id="dropdown-basic1"
                              >
                                {moneyFormatter.currencyFullWithIcon(
                                  selectedWithdrwalWallet
                                )}
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="wallets_menu">
                                {!!userBalance.wallets.length &&
                                  userBalance.wallets.map((item, index) => {
                                    return (
                                      <Dropdown.Item as="button" key={index}>
                                        <div
                                          className="wallets_balance"
                                          onClick={() =>
                                            setSelectedWithdrwalWallet(
                                              item.currency
                                            )
                                          }
                                        >
                                          {moneyFormatter.currencyFullWithIcon(
                                            item.currency
                                          )}
                                        </div>
                                      </Dropdown.Item>
                                    );
                                  })}
                              </Dropdown.Menu>
                            </Dropdown>
                            <div className="clearfix"></div>
                          </div>

                          <div className="privacy_text bordered_text">
                            Minimum withdrawal is $50.00. You withdrawal will
                            have $10.50 subtracted from your requested
                            withdrawal amount to cover the fee required to
                            process the transaction on the blockchain.
                          </div>
                          <div className="input_item">
                            <div className="name">
                              {selectedWithdrwalWallet} Address
                              <span className="requried_icon">*</span>
                            </div>
                            <input
                              type="text"
                              value={withdrwalWalletAddress}
                              onChange={(ev) =>
                                setWithdrwalWalletAddress(ev.target.value)
                              }
                              className="form-control name_value"
                            />
                            {/* <i className="fal fa-copy in_input_icon"></i> */}
                          </div>
                          <div className="input_item">
                            <div className="name">
                              Amount
                              <span className="requried_icon">*</span>
                            </div>
                            <div className="input-group">
                              <input
                                type="number"
                                className="form-control name_value"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                value={withdrwalAmount}
                                onChange={(ev) =>
                                  setWithdrwalAmount(ev.target.value)
                                }
                              />
                              <div className="currency_name">USD</div>
                              <div className="input-group-append">
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setWithdrwalAmount(
                                      userBalance.wallets[0].balance_fiat.replace(
                                        ",",
                                        ""
                                      )
                                    );
                                  }}
                                  className="input-group-text max_lavel"
                                  id="basic-addon2"
                                >
                                  MAX
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="login_button">
                            <Button
                              disabled={
                                withdrwalAmount < 50 || !withdrwalWalletAddress
                              }
                              onClick={withdraw}
                              className="login_btn"
                            >
                              {withdrwalAmount >= 50
                                ? "Withdraw ($" +
                                  (withdrwalAmount - 10.5).toFixed(2) +
                                  " after fee)"
                                : "Min amount is $50.00"}
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default HeaderWalletBalance;
