import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "../services/api";
import BetHistoryMobileDataView from "./players/parts/BetHistoryMobileDataView";
import BetHistoryDataView from "./players/parts/BetHistoryDataView";
import { isMobile } from "../services/isMobile";
import Pagination from "../elements/Pagination";

export default function BetHistory(props) {
  //pagination data

  const [activePage, setActivePage] = useState(1);
  const [page, setPage] = useState(1);

  const pageNum = (receiveVal) => {
    setPage(receiveVal);
  };

  const [gameTransactions, setGameTransactions] = useState({});
  const [gameTransactionPagination, setGameTransactionPagination] = useState(
    {}
  );
  const getGameTransactions = async (ev) => {
    var response = await api.post("/history/gaming", { page: page });
    if (response.status === 200) {
      setGameTransactions(response.data.data);
      setGameTransactionPagination(response.data.paginationData);
    }
    console.log("gameTransactions", response.data);
  };
  useEffect(() => {
    getGameTransactions();
  }, [page]);

  //Mobile Device detection
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  useEffect(() => {
    setIsMobileDevice(isMobile());
  }, []);

  return (
    <>
      <div className="profile box_container">
        <div className="profile_right_content">
          <div className="profile_headline">
            Gameplay History
            <span className="toggle_content">
              <div class="transaction_mode d-none">
                <Link
                  to="#"
                  // onClick={() => toggleTab("login")}
                  className="mode_btn"
                >
                  Deposits
                </Link>
                <Link
                  to="#"
                  // onClick={() => toggleTab("Sign up")}
                  className="mode_btn active"
                >
                  Withdrawals
                </Link>
              </div>
            </span>
          </div>
          <br />
          <div className="clearfix"></div>

          {!isMobileDevice && <BetHistoryDataView dataSet={gameTransactions} />}

          {isMobileDevice && (
            <BetHistoryMobileDataView dataSet={gameTransactions} />
          )}

          <Pagination
            paginationData={gameTransactionPagination}
            pageActivate={activePage}
            pageNum={pageNum}
          />
        </div>
      </div>
    </>
  );
}
